
    <template>
      <section class="markdown-container theme-default-content">
      <head class="markdown"></head><body class="markdown"><h1 class="markdown">色彩 Color</h1>
<h2 id="色彩体系" class="markdown"><a class="header-anchor markdown" href="#色彩体系">#</a> 色彩体系</h2>
<p class="markdown">Cube Design 定义了一套彩色色板和一套中性色板，覆盖了企业内部产品设计中需要用到的大部分颜色。</p>
<p class="markdown">每个色板各有 10 个“色阶”，包含 1 个主色及 9 个衍生色。</p>
<p class="markdown">色阶编号采用 0-9 命名，色阶颜色深浅均和数值成正比。即：0 最浅，9 最深，编号 5 作为这个色板的主色。</p>
<h3 id="主题色" class="markdown"><a class="header-anchor markdown" href="#主题色">#</a> 主题色</h3>
<p class="markdown">主题色定义产品的整体基调。一般使用公司品牌 VI 的核心颜色（例如 Logo 的颜色）。</p>
<p class="markdown">Cube Design 参考建发的品牌色，使用充满活力的蓝色作为主题色。</p>
<blockquote class="markdown">
<p class="markdown">注：<br class="markdown">
品牌色有时候也叫做“强调色”，用来强调 UI 中关键部分的颜色。<br class="markdown">
强调色一般使用品牌色或其衍生色，也可以使用不同于主色的另一种颜色，我们采用前者。</p>
</blockquote>
<demo-block render-source="true" hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo0 class="markdown"></element-demo0></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;div class="cdm-docs-color-brand"&gt;
  &lt;p&gt;品牌蓝&lt;/p&gt;
  &lt;div class="cdm-docs-color-brand-b"&gt;
    &lt;p&gt;rgba(18, 89, 229, 1)&lt;/p&gt;
    &lt;p&gt;#1259E5&lt;/p&gt;
  &lt;/div&gt;
&lt;/div&gt;
</code></pre></template></demo-block><h3 id="功能色" class="markdown"><a class="header-anchor markdown" href="#功能色">#</a> 功能色</h3>
<p class="markdown">功能色代表了明确的信息以及状态，比如成功、出错、失败、提醒、链接等。功能色的选取需要遵守用户对色彩的基本认知。
我们建议在一套产品体系下，功能色尽量保持一致，不要有过多的自定义干扰用户的认知体验。</p>
<p class="markdown">Cube Design 选用红、橙、绿作为功能色，他们的建议应用场景如下：</p>
<ul class="markdown">
<li class="markdown">品牌色表示强调：用于主按钮、选中信息、高亮、链接等场景</li>
<li class="markdown">红色表示错误：用于报错、失败、移除等操作或状态</li>
<li class="markdown">橙色表示警告：用于警告、代办、提醒、丢失等操作或状态</li>
<li class="markdown">绿色表示成功：用于保存、创建、添加、完成等操作的成功提示；或是用来表示新创建、已完成、已解决的状态</li>
</ul>
<blockquote class="markdown">
<p class="markdown"><strong class="markdown">点击色板可复制颜色的色值！</strong></p>
</blockquote>
<demo-block hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo1 class="markdown"></element-demo1></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;template&gt;
  &lt;div class="cdm-docs-color-palettes chromatic"&gt;
    &lt;div
      v-for="(item, index) in listFeatures"
      :key="index"
      class="cdm-docs-color-palettes-list"
    &gt;
      &lt;div
        v-for="(listLi, itemIndex) in item"
        :key="itemIndex"
        class="cdm-docs-color-palettes-list-item"
        :style="{ background: listLi.hex }"
        @click="copyColor(listLi.hex)"
      &gt;
        &lt;p v-if="listLi.topTitle" class="cdm-docs-color-palettes-list-item-top"&gt;
          {{ listLi.topTitle }}
        &lt;/p&gt;
        &lt;p class="cdm-docs-color-palettes-list-item-bottom"&gt;
          &lt;span&gt;{{ listLi.colorName }}&lt;/span&gt;
          &lt;span&gt;{{ listLi.hex }}&lt;/span&gt;
        &lt;/p&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import ColorMixin from "./mixin"

export default {
  mixins: [ColorMixin]
}
&lt;/script&gt;

&lt;style lang="scss" scoped&gt;
@import "./color.scss";
&lt;/style&gt;
</code></pre></template></demo-block><h3 id="中性色" class="markdown"><a class="header-anchor markdown" href="#中性色">#</a> 中性色</h3>
<p class="markdown">中性色包含了黑、白、灰三个色板。</p>
<p class="markdown">中性色板是界面中占据面积最大的颜色，大量用于文字、边框、分割线、背景色、遮罩等场景。</p>
<p class="markdown">在灰色的选择上，我们将传统灰色和品牌蓝色进行了融合，最终使用蓝灰代替了传统的灰色，让界面整体的品牌调性更加凸显。</p>
<p class="markdown">“黑色”和“白色”是两个特殊的色板。他们也各有 10 个颜色，编号 0 分别表示纯黑和纯白，随着数字增大，颜色的透明度也随之增加，从而形成灰度。因此除了纯黑和纯白以外，剩余颜色的通常用于需要透明度的场景，最典型的是作为"遮罩"。</p>
<p class="markdown">默认在亮色主题下使用黑色色板，在暗黑主题下使用白色色板。</p>
<blockquote class="markdown">
<p class="markdown"><strong class="markdown">注: 蓝灰色板无透明度</strong>.</p>
</blockquote>
<demo-block hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo2 class="markdown"></element-demo2></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;template&gt;
  &lt;div class="cdm-docs-color-palettes neutrals"&gt;
    &lt;div
      v-for="(item, index) in listNeutralAll"
      :key="index"
      class="cdm-docs-color-palettes-list"
      :class="index"
    &gt;
      &lt;div
        v-for="(listLi, itemIndex) in item"
        :key="itemIndex"
        class="cdm-docs-color-palettes-list-item"
        :style="{ background: listLi.hex }"
        @click="copyColor(listLi.hex)"
      &gt;
        &lt;p class="cdm-docs-color-palettes-list-item-bottom"&gt;
          &lt;span&gt;{{ listLi.colorName }}&lt;/span&gt;
          &lt;span&gt;{{ listLi.aliasHex || listLi.hex }}&lt;/span&gt;
        &lt;/p&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import ColorMixin from "./mixin"

export default {
  mixins: [ColorMixin]
}
&lt;/script&gt;

&lt;style lang="scss" scoped&gt;
@import "./color.scss";
&lt;/style&gt;
</code></pre></template></demo-block><h3 id="扩展色" class="markdown"><a class="header-anchor markdown" href="#扩展色">#</a> 扩展色</h3>
<p class="markdown">在颜色使用较复杂的场景下（如数据可视化），可使用扩展色板（衍生色）进行搭配。</p>
<p class="markdown">若扩展色也无法满足需求，允许自定义颜色，或使用第三方组件自带的颜色。</p>
<demo-block hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo3 class="markdown"></element-demo3></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;template&gt;
  &lt;div class="cdm-docs-color-palettes chromatic"&gt;
    &lt;div
      v-for="(item, index) in listExpand"
      :key="index"
      class="cdm-docs-color-palettes-list"
    &gt;
      &lt;div
        v-for="(listLi, itemIndex) in item"
        :key="itemIndex"
        class="cdm-docs-color-palettes-list-item"
        :style="{ background: listLi.hex }"
        @click="copyColor(listLi.hex)"
      &gt;
        &lt;p v-if="listLi.topTitle" class="cdm-docs-color-palettes-list-item-top"&gt;
          {{ listLi.topTitle }}
        &lt;/p&gt;
        &lt;p class="cdm-docs-color-palettes-list-item-bottom"&gt;
          &lt;span&gt;{{ listLi.colorName }}&lt;/span&gt;
          &lt;span&gt;{{ listLi.hex }}&lt;/span&gt;
        &lt;/p&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import ColorMixin from "./mixin"

export default {
  mixins: [ColorMixin]
}
&lt;/script&gt;

&lt;style lang="scss" scoped&gt;
@import "./color.scss";
&lt;/style&gt;
</code></pre></template></demo-block><h2 id="颜色状态" class="markdown"><a class="header-anchor markdown" href="#颜色状态">#</a> 颜色状态</h2>
<p class="markdown">在不同的交互场景下，颜色需要切换至不同的状态，以满足不同场景的使用。</p>
<p class="markdown">除了默认状态外，常见的还有激活、悬停、禁用三种状态。我们分别采用色板中的 7、4、1 编号表示上述三种颜色。</p>
<blockquote class="markdown">
<p class="markdown">有些场景（例如表单）可能会有“只读”状态，但“只读”的样式一般不会发生变化，所以不单独作为一种颜色状态。</p>
</blockquote>
<demo-block hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo4 class="markdown"></element-demo4></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;template&gt;
  &lt;div class="cdm-docs-color-states"&gt;
    &lt;div
      v-for="(item, index) in cubeColorStates"
      :key="index"
      class="cdm-docs-color-states-list"
    &gt;
      &lt;p class="cdm-docs-color-states-list-name"&gt;
        {{ getColorStateName(index) }}
      &lt;/p&gt;
      &lt;div class="cdm-docs-color-states-list-items"&gt;
        &lt;div
          v-for="(listLi, itemIndex) in item"
          :key="itemIndex"
          class="cdm-docs-color-states-list-item"
          :style="{ background: listLi.hex }"
          @click="copyColor(listLi.hex)"
        &gt;
          &lt;p class="cdm-docs-color-states-list-item-state"&gt;
            {{ listLi.state }}
          &lt;/p&gt;
          &lt;p class="cdm-docs-color-states-list-item-bottom"&gt;
            &lt;span&gt;{{ listLi.colorName }}&lt;/span&gt;
            &lt;span&gt;{{ listLi.hex }}&lt;/span&gt;
          &lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import ColorMixin from "./mixin"

export default {
  mixins: [ColorMixin]
}
&lt;/script&gt;

&lt;style lang="scss" scoped&gt;
@import "./color.scss";
&lt;/style&gt;
</code></pre></template></demo-block><!-- ### 动态生成状态色
### 激活状态 Active

激活状态用于表示按钮被按下。

对于激活状态下的颜色变化，我们不采用直接定义色号的方式，而是用[Alpha 合成](https://zh.wikipedia.org/zh-hans/Alpha合成)公式中的 `over` 操作来计算:

    有了Alpha通道，图片的合成操作就可以用合成代数的形式表达。假设有图像元素
    A 和 B，最常见的合成操作就是把 A 作为前景、B
    作为背景，我们称这种操作（运算）为 over，记作 A over B.

    公式如下:

    outputRed = (foregroundRed * foregroundAlpha) + (backgroundRed * (1.0 - foregroundAlpha));

我们定义激活状态的合成规则如下:

- 正常状态作为背景色，在其上叠加一个 15% 透明纯黑的前景色

### 禁用状态 Disabled

禁用状态用于表示被禁止操作的按钮、输入框、选择器和表单等组件。
一般是由于权限或预置条件未符合要求而产生禁用状态。禁用状态的组件下完全没有任何交互功能，例如无悬浮效果，无法聚焦，不可点击等。

禁用状态的颜色需要区分彩色和中性色板。

- 对于彩色色板，使用纯白作为背景色，叠加 30%
  透明的该颜色正常状态作为前景色
- 对于中性色板，一律使用蓝灰色板最浅的 `#F2F3F5`{.verbatim}
  作为禁用状态的颜色 -->
<h2 id="开发" class="markdown"><a class="header-anchor markdown" href="#开发">#</a> 开发</h2>
<p class="markdown">我们为开发提供了 JavaScript 和 Sass 两种使用方式。</p>
<div class="language-sh markdown"><pre v-pre="" class="markdown"><code class="markdown"><span class="token comment markdown"># 安装设计规范依赖包</span>
<span class="token function markdown">npm</span> i @cndinfo/cube-design-styles <span class="token parameter variable markdown">--registry</span> https://npm.devops.cndinfo.com/
</code></pre>
</div><h3 id="sass" class="markdown"><a class="header-anchor markdown" href="#sass">#</a> Sass</h3>
<p class="markdown">Sass 变量的命名使用 <code class="markdown">$cube--色板-编号</code> 的格式。</p>
<p class="markdown">纯黑和纯白额外支持简写：<code class="markdown">$cube-white</code> 和 <code class="markdown">$cube-black</code>。</p>
<div class="language-css markdown"><pre v-pre="" class="markdown"><code class="markdown"><span class="token comment markdown">/* 导入所有设计规范变量 */</span>
<span class="token atrule markdown"><span class="token rule markdown">@import</span> <span class="token string markdown">"@cndinfo/cube-design-styles/index"</span><span class="token punctuation markdown">;</span></span>

<span class="token comment markdown">/* 仅导入颜色变量 */</span>
<span class="token atrule markdown"><span class="token rule markdown">@import</span> <span class="token string markdown">"@cndinfo/cube-design-styles/colors"</span><span class="token punctuation markdown">;</span></span>

<span class="token selector markdown">.test</span> <span class="token punctuation markdown">{</span>
  <span class="token property markdown">background-color</span><span class="token punctuation markdown">:</span> $cube--blue-5<span class="token punctuation markdown">;</span>
  <span class="token property markdown">color</span><span class="token punctuation markdown">:</span> $cube-black<span class="token punctuation markdown">;</span>
<span class="token punctuation markdown">}</span>
</code></pre>
</div><h3 id="javascript" class="markdown"><a class="header-anchor markdown" href="#javascript">#</a> JavaScript</h3>
<p class="markdown">我们既提供单个色板对象，也提供单个颜色变量。</p>
<div class="language-js markdown"><pre v-pre="" class="markdown"><code class="markdown"><span class="token comment markdown">// 全部导入</span>
<span class="token keyword markdown">import</span> <span class="token operator markdown">*</span> <span class="token keyword markdown">as</span> cubeColors <span class="token keyword markdown">from</span> <span class="token string markdown">"@cndinfo/cube-design-styles/colors/variables"</span>

<span class="token comment markdown">// 导入某个色板</span>
<span class="token keyword markdown">import</span> <span class="token punctuation markdown">{</span> blue <span class="token punctuation markdown">}</span> <span class="token keyword markdown">from</span> <span class="token string markdown">"@cndinfo/cube-design-styles/colors/variables"</span>
console<span class="token punctuation markdown">.</span><span class="token function markdown">log</span><span class="token punctuation markdown">(</span>blue<span class="token punctuation markdown">)</span> <span class="token comment markdown">// { "0": "#E6F2FF", "1": "#B8D9FF", "2": "#8FBFFF", "3": "#66A3FF", "4": "#397AEA", "5": "#1259E5", "6": "#063DBF", "7": "#002999", "8": "#001B73", "9": "#000F4D" }</span>
console<span class="token punctuation markdown">.</span><span class="token function markdown">log</span><span class="token punctuation markdown">(</span>blue<span class="token punctuation markdown">[</span><span class="token number markdown">5</span><span class="token punctuation markdown">]</span><span class="token punctuation markdown">)</span> <span class="token comment markdown">// #1259E5</span>

<span class="token comment markdown">// 导入某个颜色</span>
<span class="token keyword markdown">import</span> <span class="token punctuation markdown">{</span> blue5<span class="token punctuation markdown">,</span> magenta9 <span class="token punctuation markdown">}</span> <span class="token keyword markdown">from</span> <span class="token string markdown">"@cndinfo/cube-design-styles/colors/variables"</span>
console<span class="token punctuation markdown">.</span><span class="token function markdown">log</span><span class="token punctuation markdown">(</span>blue5<span class="token punctuation markdown">)</span> <span class="token comment markdown">// #1259E5</span>
</code></pre>
</div></body>
      </section>
    </template>
    <script>
    
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"cdm-docs-color-brand"},[_c('p',[_vm._v("品牌蓝")]),_vm._v(" "),_c('div',{staticClass:"cdm-docs-color-brand-b"},[_c('p',[_vm._v("rgba(18, 89, 229, 1)")]),_vm._v(" "),_c('p',[_vm._v("#1259E5")])])])])}]

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),"element-demo1": ()=>import('./palette-functional.vue'),"element-demo2": ()=>import('./palette-neutral.vue'),"element-demo3": ()=>import('./palette-extended.vue'),"element-demo4": ()=>import('./color-states.vue'),
        }
      }
    </script>
  