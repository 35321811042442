import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import { siteConfig } from "config/site"
import "./utils/auto-register"

import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI)

import cubeDesignWeb from "@cndinfo/cube-design-web"
Vue.use(cubeDesignWeb)

import CubeIcon from "@cndinfo/cube-design-icons"
Vue.use(CubeIcon)

Vue.config.productionTip = false
Vue.prototype.$localePath = "/"
Vue.prototype.$site = Object.assign({}, siteConfig)

Vue.config.silent = true
new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app")
