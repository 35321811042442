
    <template>
      <section class="markdown-container theme-default-content">
      <head class="markdown"></head><body class="markdown"><h1 class="markdown">字体 Typography</h1>
<h2 id="字体家族" class="markdown"><a class="header-anchor markdown" href="#字体家族">#</a> 字体家族</h2>
<p class="markdown">Cube Design 使用系统默认的无衬线体作为界面字体。</p>
<h3 id="中英文字体" class="markdown"><a class="header-anchor markdown" href="#中英文字体">#</a> 中英文字体</h3>
<p class="markdown">我们在使用系统默认字体的基础上，定义了一组字体库的显示顺序，保证在不同平台及浏览器的显示下，字体始终保持良好的显示效果，同时兼容英文界面。</p>
<div class="language-css markdown"><pre v-pre="" class="markdown"><code class="markdown"><span class="token property markdown">font-family</span><span class="token punctuation markdown">:</span> -apple-system<span class="token punctuation markdown">,</span> BlinkMacSystemFont<span class="token punctuation markdown">,</span> <span class="token string markdown">"PingFang SC"</span><span class="token punctuation markdown">,</span>
  <span class="token string markdown">"Microsoft Yahei"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Helvetica Neue"</span><span class="token punctuation markdown">,</span> Helvetica<span class="token punctuation markdown">,</span> <span class="token string markdown">"Segoe UI"</span><span class="token punctuation markdown">,</span> Roboto<span class="token punctuation markdown">,</span> Arial<span class="token punctuation markdown">,</span>
  <span class="token string markdown">"Noto Sans"</span><span class="token punctuation markdown">,</span> sans-serif<span class="token punctuation markdown">,</span> <span class="token string markdown">"Apple Color Emoji"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Segoe UI Emoji"</span><span class="token punctuation markdown">,</span>
  <span class="token string markdown">"Segoe UI Symbol"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Noto Color Emoji"</span><span class="token punctuation markdown">;</span>
</code></pre>
</div><ul class="markdown">
<li class="markdown"><code class="markdown">-apple-system</code>, <code class="markdown">BlinkMacSystemFont</code>, <code class="markdown">'PingFang SC'</code> 保证不同版本 macOS 下的显示效果</li>
<li class="markdown"><code class="markdown">'Microsoft Yahei'</code> 实现在 Windows 7 以上的 Windows 系统中均使用"微软雅黑"字体</li>
<li class="markdown"><code class="markdown">'Helvetica Neue'</code>, <code class="markdown">Helvetica</code>, <code class="markdown">'Segoe UI'</code>, <code class="markdown">Roboto</code>, <code class="markdown">Arial</code>, <code class="markdown">'Noto Sans'</code> 定义了一组经典的英文的字体，保证在英文系统中的显示效果</li>
<li class="markdown"><code class="markdown">sans-serif</code> 作为最终的回退（fallback）方案，确保即使缺失了上述所有字体，仍然可以使用无衬线体显示</li>
<li class="markdown">最后四个 Emoji 字体用于显示表情符号</li>
</ul>
<h3 id="品牌字体" class="markdown"><a class="header-anchor markdown" href="#品牌字体">#</a> 品牌字体</h3>
<p class="markdown">我们公司采用开源的"<a href="https://github.com/adobe-fonts/source-han-sans/blob/master/README-CN.md" class="markdown">思源黑体</a>"作为品牌 VI 字体。</p>
<p class="markdown">因此，如果想要系统界面更具有公司的品牌调性，可以将思源黑体放在第一顺位，有安装思源黑体的系统便会优先使用该字体。</p>
<div class="language-css markdown"><pre v-pre="" class="markdown"><code class="markdown"><span class="token property markdown">font-family</span><span class="token punctuation markdown">:</span> <span class="token string markdown">"Source Han Sans CN"</span><span class="token punctuation markdown">,</span> -apple-system<span class="token punctuation markdown">,</span> BlinkMacSystemFont<span class="token punctuation markdown">,</span> <span class="token string markdown">"PingFang SC"</span><span class="token punctuation markdown">,</span>
  <span class="token string markdown">"Microsoft Yahei"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Helvetica Neue"</span><span class="token punctuation markdown">,</span> Helvetica<span class="token punctuation markdown">,</span> <span class="token string markdown">"Segoe UI"</span><span class="token punctuation markdown">,</span> Roboto<span class="token punctuation markdown">,</span> Arial<span class="token punctuation markdown">,</span> <span class="token string markdown">"Noto Sans"</span><span class="token punctuation markdown">,</span>
  sans-serif<span class="token punctuation markdown">,</span> <span class="token string markdown">"Apple Color Emoji"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Segoe UI Emoji"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Segoe UI Symbol"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Noto Color Emoji"</span><span class="token punctuation markdown">;</span>
</code></pre>
</div><!-- 对于没有安装该字体的系统，我们也在 cube-design 库中提供了自定义 `@font-face`, 任意系统都可以通过加载远程字体的方式，使用思源黑体。\
我们使用 CDN 进行字体分发，确保全国各地加载字体的时间不会超过 100ms。

当然，最好的方式还是让用户在本地安装字体。

思源黑体下载地址: TODO -->
<h3 id="数字字体" class="markdown"><a class="header-anchor markdown" href="#数字字体">#</a> 数字字体</h3>
<p class="markdown">将数字字体设置为等宽，可以提升表格等特殊区域的显示效果，尤其是纵向的数字对比展示。</p>
<div class="language-css markdown"><pre v-pre="" class="markdown"><code class="markdown"><span class="token property markdown">font-variant</span><span class="token punctuation markdown">:</span> tabular-nums<span class="token punctuation markdown">;</span>
</code></pre>
</div><p class="markdown">以 macOS 默认字体 PingFang 为例，可以看下设置前后的对比：</p>
<p class="markdown"><img src="/static/images/design/guidelines/tabular-nums.png" alt="tabular-nums" class="markdown"></p>
<p class="markdown">需要注意的是，该属性需要字体本身支持，且仅针对数字生效，对于小数点或是英文字母无效。</p>
<p class="markdown">其实部分字体的数字本身就是等宽的，例如我们 <code class="markdown">font-family</code> 中定义的字体，从 Microsoft Yahei 到 Noto Sans 都是如此。<br class="markdown">
但上述提到的 PingFang 就不等宽，因次我们建议始终加上这个属性，保证跨平台的体验。</p>
<h3 id="代码字体" class="markdown"><a class="header-anchor markdown" href="#代码字体">#</a> 代码字体</h3>
<p class="markdown">我们为代码显示选了两款经典的内置 mono 字体。 Consolas 为 Windows 内置，Menlo 为 macOS 内置。</p>
<div class="language-css markdown"><pre v-pre="" class="markdown"><code class="markdown"><span class="token property markdown">font-family</span><span class="token punctuation markdown">:</span> Consolas<span class="token punctuation markdown">,</span> Menlo<span class="token punctuation markdown">,</span> monospace<span class="token punctuation markdown">;</span>
</code></pre>
</div><h2 id="字阶与行高" class="markdown"><a class="header-anchor markdown" href="#字阶与行高">#</a> 字阶与行高</h2>
<p class="markdown">Cube Design 选择 14 作为界面的主字体（正文字体），这个大小可以在电脑和移动端都保持优秀的阅读体验。</p>
<h3 id="字阶表" class="markdown"><a class="header-anchor markdown" href="#字阶表">#</a> 字阶表</h3>
<p class="markdown">我们一共定义了 9 种字阶与行高，以保证从角标到标题都有合适的字体大小可以选择。<br class="markdown">
所有字体大小均采用偶数，确保垂直居中时上下边距始终相等。</p>
<p class="markdown"><img src="/static/images/design/guidelines/typescale-table.png" alt="typescale-table" class="markdown"></p>
<p class="markdown">字阶梯度分为 <code class="markdown">2px</code> 和 <code class="markdown">4px</code>，如果用户有超过 <code class="markdown">32px</code> 的需求，可以按 <code class="markdown">+4px</code> 梯度自行设置更大的字体。</p>
<p class="markdown">行高分别采用字体大小 <code class="markdown">+4</code>, <code class="markdown">+6</code>, <code class="markdown">+8</code> 三种梯度。</p>
<p class="markdown">由于倍数行高（例如 1.5）在字号越大时间距也越大，对大号字体显示不够友好，因此我们采用固定数值作为行高。</p>
<blockquote class="markdown">
<p class="markdown">下表中的灰色表示行高。</p>
</blockquote>
<demo-block hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo0 class="markdown"></element-demo0></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;template&gt;
  &lt;div class="cdm-docs-font-scale"&gt;
    &lt;div class="cdm-docs-font-scale-title"&gt;
      &lt;span class="name"&gt;别名&lt;/span&gt;
      &lt;span class="height"&gt;行高&lt;/span&gt;
      &lt;span class="step"&gt;字阶梯度&lt;/span&gt;
      &lt;span class="size"&gt;字号&lt;/span&gt;
    &lt;/div&gt;
    &lt;template v-for="(item, i) in fontList"&gt;
      &lt;div
        :key="i"
        :class="['font-scale-' + item.fontSize, i]"
        :style="{
          height: item.lineHeight + 'px'
        }"
      &gt;
        &lt;span class="name"&gt;{{ item.name }}&lt;/span&gt;
        &lt;span class="height"&gt;{{ item.lineHeight }}px&lt;/span&gt;
        &lt;span class="step"&gt;{{ item.step }}&lt;/span&gt;
        &lt;span
          class="size"
          :style="{
            fontSize: item.fontSize + 'px',
            lineHeight: item.lineHeight + 'px'
          }"
        &gt;{{ item.size }}&lt;/span&gt;
      &lt;/div&gt;
    &lt;/template&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import Mixin from "./mixin"

export default {
  mixins: [Mixin]
}
&lt;/script&gt;

&lt;style lang="scss" scoped&gt;
@import "./typography.scss";
&lt;/style&gt;
</code></pre></template></demo-block><h3 id="字阶应用" class="markdown"><a class="header-anchor markdown" href="#字阶应用">#</a> 字阶应用</h3>
<p class="markdown">常用字阶尽量控制在 5 种以内: <code class="markdown">h6</code>, <code class="markdown">headline</code>, <code class="markdown">subhead</code>, <code class="markdown">body</code>, <code class="markdown">caption</code>。<br class="markdown">
在一些展示型页面中，可以考虑 <code class="markdown">h6</code> 以上的字阶。<br class="markdown">
在角标、图标等需要极小字体的场景下，可以使用 <code class="markdown">footnote</code>。</p>
<h3 id="行高应用" class="markdown"><a class="header-anchor markdown" href="#行高应用">#</a> 行高应用</h3>
<p class="markdown">对于字体行高的选择有两种:</p>
<ol class="markdown">
<li class="markdown">和字体等高（1 倍）</li>
<li class="markdown">字阶表中的行高值（&gt;1 倍）</li>
</ol>
<p class="markdown">如果是多行的段落类文本，选择字阶表中的行高值，保证文字的阅读效果。<br class="markdown">
如果是组件内的单行文本（如 Button），可以采用 1 倍行高，避免产生不必要的间距。</p>
<h2 id="字体颜色" class="markdown"><a class="header-anchor markdown" href="#字体颜色">#</a> 字体颜色</h2>
<p class="markdown">文本颜色如果和背景颜色太接近就会难以阅读。考虑到无障碍设计的需求，结合 WCAG 的标准，将正文文本、标题和背景色之间保持在 7:1 以上的 AAA 级对比度。其余大号文本尽量接近 3:1。</p>
<demo-block hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo1 class="markdown"></element-demo1></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;template&gt;
  &lt;div class="cdm-docs-font-color"&gt;
    &lt;p class="note"&gt;右上角为按 WCAG 规范计算的颜色对比度。&lt;/p&gt;
    &lt;div
      v-for="(item, index) in fontColorList"
      :key="index"
      :class="index"
      class="cdm-docs-font-color-list"
      :style="{ background: index === 'dark' ? 'black' : '' }"
    &gt;
      &lt;div
        v-for="(subItem, itemIndex) in item"
        :key="itemIndex"
        class="cdm-docs-font-color-list-item"
      &gt;
        &lt;div class="cdm-docs-font-color-list-item-top"&gt;
          &lt;p
            class="cdm-docs-font-color-list-item-content"
            :style="{ color: subItem.hex }"
          &gt;
            Aa
          &lt;/p&gt;
          &lt;span&gt;{{
            subItem.contrast || hexContrast(subItem.hex, "#ffffff")
          }}&lt;/span&gt;
        &lt;/div&gt;
        &lt;div
          class="cdm-docs-font-color-list-item-bar"
          :style="{ background: subItem.hex }"
          @click="copyColor(subItem.hex)"
        /&gt;
        &lt;div class="cdm-docs-font-color-list-item-bottom"&gt;
          &lt;span class="cdm-docs-font-color-list-item-name"&gt;
            {{ subItem.name }}
            {{ index !== "color" ? ` - ${subItem.color}` : "" }}
          &lt;/span&gt;
          &lt;span&gt;{{ subItem.aliasHex || subItem.hex.toUpperCase() }}&lt;/span&gt;
        &lt;/div&gt;
        &lt;p class="cdm-docs-font-color-list-item-desc"&gt;
          {{ subItem.desc }}
        &lt;/p&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import Mixin from "./mixin"

export default {
  mixins: [Mixin]
}
&lt;/script&gt;

&lt;style lang="scss" scoped&gt;
@import "./typography.scss";
&lt;/style&gt;
</code></pre></template></demo-block><h2 id="字体样式" class="markdown"><a class="header-anchor markdown" href="#字体样式">#</a> 字体样式</h2>
<h3 id="字重" class="markdown"><a class="header-anchor markdown" href="#字重">#</a> 字重</h3>
<p class="markdown">像思源黑体、苹方这类现代化的字体，都支持多重的字重效果。但为了更好的兼容性，我们只定义两种字重：常规和加粗。</p>
<demo-block render-source="true" hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo2 class="markdown"></element-demo2></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;div style="font-size: 56px"&gt;
  &lt;p style="font-weight: bold; margin-bottom: 16px;"&gt;欢迎使用 Cube Design&lt;/p&gt;
  &lt;p&gt;欢迎使用 Cube Design&lt;/p&gt;
&lt;/div&gt;
</code></pre></template></demo-block><p class="markdown">在开发过程中，我们也建议使用 <code class="markdown">normal</code> 和 <code class="markdown">bold</code> 来定义字重，而不是采用数值。<br class="markdown">
原因是不同数值在不同字体中对应效果不一致。比如 <code class="markdown">font-weight: 500</code> 在思源黑体中对应 Medium（中粗体），而在微软雅黑中则是 Normal，无法实现加粗。</p>
<p class="markdown">如果是一些展示性页面，在确保加载的字体会有多重字重的情况下，可以采用数值字重。具体大小不做限制。</p>
<h3 id="斜体" class="markdown"><a class="header-anchor markdown" href="#斜体">#</a> 斜体</h3>
<p class="markdown">中文字体很少采用斜体样式，不建议使用。</p>
<p class="markdown">英文字体可采用斜体，但使用时应符合英文写作规范，仅在表示变量（术语）、强调文字、辅助文字等情况下使用，不能滥用。</p>
<h2 id="开发" class="markdown"><a class="header-anchor markdown" href="#开发">#</a> 开发</h2>
<p class="markdown">Cube Design 移动组件库已内置了一套字体规范变量，可直接使用以下变量。</p>
<p class="markdown">若未使用我们组件库，开发人员也可复制下面变量至项目的全局样式文件中。</p>
<div class="language-css markdown"><pre v-pre="" class="markdown"><code class="markdown"><span class="token atrule markdown"><span class="token rule markdown">@import</span> <span class="token string markdown">"@cndinfo/cube-design-styles/index"</span><span class="token punctuation markdown">;</span></span>

<span class="token property markdown">--cdm-theme-font-family</span><span class="token punctuation markdown">:</span> -apple-system<span class="token punctuation markdown">,</span> blinkmacsystemfont<span class="token punctuation markdown">,</span> <span class="token string markdown">"PingFang SC"</span><span class="token punctuation markdown">,</span>
  <span class="token string markdown">"Microsoft Yahei"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Source Han Sans CN"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Helvetica Neue"</span><span class="token punctuation markdown">,</span> helvetica<span class="token punctuation markdown">,</span>
  <span class="token string markdown">"Segoe UI"</span><span class="token punctuation markdown">,</span> roboto<span class="token punctuation markdown">,</span> arial<span class="token punctuation markdown">,</span> <span class="token string markdown">"Noto Sans"</span><span class="token punctuation markdown">,</span> sans-serif<span class="token punctuation markdown">,</span> <span class="token string markdown">"Apple Color Emoji"</span><span class="token punctuation markdown">,</span>
  <span class="token string markdown">"Segoe UI Emoji"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Segoe UI Symbol"</span><span class="token punctuation markdown">,</span> <span class="token string markdown">"Noto Color Emoji"</span><span class="token punctuation markdown">;</span> <span class="token comment markdown">/* 字体家族 */</span>
<span class="token property markdown">--cdm-theme-font-code-family</span><span class="token punctuation markdown">:</span> <span class="token string markdown">"Roboto Mono"</span><span class="token punctuation markdown">,</span> monaco<span class="token punctuation markdown">,</span> courier<span class="token punctuation markdown">,</span> monospace<span class="token punctuation markdown">;</span> <span class="token comment markdown">/* 代码字体家族 */</span>
<span class="token property markdown">--cdm-theme-font-variant</span><span class="token punctuation markdown">:</span> tabular-nums<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-footnote</span><span class="token punctuation markdown">:</span> 10px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-overline</span><span class="token punctuation markdown">:</span> 11px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-caption</span><span class="token punctuation markdown">:</span> 12px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-body</span><span class="token punctuation markdown">:</span> 14px<span class="token punctuation markdown">;</span> <span class="token comment markdown">/* 正文字体大小 */</span>
<span class="token property markdown">--cdm-theme-font-size-subhead</span><span class="token punctuation markdown">:</span> 16px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-headline</span><span class="token punctuation markdown">:</span> 18px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-h6</span><span class="token punctuation markdown">:</span> 20px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-h5</span><span class="token punctuation markdown">:</span> 24px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-h4</span><span class="token punctuation markdown">:</span> 28px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-h3</span><span class="token punctuation markdown">:</span> 32px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-h2</span><span class="token punctuation markdown">:</span> 36px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-size-h1</span><span class="token punctuation markdown">:</span> 40px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-weight-regular</span><span class="token punctuation markdown">:</span> normal<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-weight-bold</span><span class="token punctuation markdown">:</span> bold<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-footnote</span><span class="token punctuation markdown">:</span> 14px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-overline</span><span class="token punctuation markdown">:</span> 15px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-caption</span><span class="token punctuation markdown">:</span> 16px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-body</span><span class="token punctuation markdown">:</span> 20px<span class="token punctuation markdown">;</span> <span class="token comment markdown">/* 正文字体行高 */</span>
<span class="token property markdown">--cdm-theme-font-line-height-subhead</span><span class="token punctuation markdown">:</span> 22px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-headline</span><span class="token punctuation markdown">:</span> 24px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-h6</span><span class="token punctuation markdown">:</span> 28px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-h5</span><span class="token punctuation markdown">:</span> 32px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-h4</span><span class="token punctuation markdown">:</span> 36px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-h3</span><span class="token punctuation markdown">:</span> 40px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-h2</span><span class="token punctuation markdown">:</span> 44px<span class="token punctuation markdown">;</span>
<span class="token property markdown">--cdm-theme-font-line-height-h1</span><span class="token punctuation markdown">:</span> 48px<span class="token punctuation markdown">;</span>
<span class="token selector markdown">--cdm-theme-font-color-principal: #</span><span class="token punctuation markdown">{</span>$cube--gray-9<span class="token punctuation markdown">}</span><span class="token punctuation markdown">;</span> <span class="token comment markdown">/* 主要字体颜色 */</span>
<span class="token selector markdown">--cdm-theme-font-color-secondary: #</span><span class="token punctuation markdown">{</span>$cube--gray-6<span class="token punctuation markdown">}</span><span class="token punctuation markdown">;</span> <span class="token comment markdown">/* 次要字体颜色 */</span>
<span class="token selector markdown">--cdm-theme-font-color-tertiary: #</span><span class="token punctuation markdown">{</span>$cube--gray-4<span class="token punctuation markdown">}</span><span class="token punctuation markdown">;</span> <span class="token comment markdown">/* 辅助字体颜色 */</span>
<span class="token selector markdown">--cdm-theme-font-color-quaternary: #</span><span class="token punctuation markdown">{</span>$cube--gray-3<span class="token punctuation markdown">}</span><span class="token punctuation markdown">;</span> <span class="token comment markdown">/* 不重要字体颜色 */</span>
<span class="token selector markdown">--cdm-theme-font-color-disabled: #</span><span class="token punctuation markdown">{</span>$cube--gray-3<span class="token punctuation markdown">}</span><span class="token punctuation markdown">;</span> <span class="token comment markdown">/* 禁用状态字体颜色 */</span>
</code></pre>
</div></body>
      </section>
    </template>
    <script>
    
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": ()=>import('./scales.vue'),"element-demo1": ()=>import('./font-color.vue'),"element-demo2": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"font-size":"56px"}},[_c('p',{staticStyle:{"font-weight":"bold","margin-bottom":"16px"}},[_vm._v("欢迎使用 Cube Design")]),_vm._v(" "),_c('p',[_vm._v("欢迎使用 Cube Design")])])])}]

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  