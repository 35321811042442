import { render, staticRenderFns } from "./palette-neutral.vue?vue&type=template&id=02e7efec&scoped=true"
import script from "./palette-neutral.vue?vue&type=script&lang=js"
export * from "./palette-neutral.vue?vue&type=script&lang=js"
import style0 from "./palette-neutral.vue?vue&type=style&index=0&id=02e7efec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02e7efec",
  null
  
)

export default component.exports