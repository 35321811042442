import _ from "lodash"
import * as cubeColors from "@cndinfo/cube-design-styles/colors/variables"

// console.log("cubeColors", cubeColors)

const COLOR_NAME = {
  black: "",
  white: "",
  gray: "蓝灰",
  blue: "蓝色",
  green: "绿色",
  red: "红色",
  orange: "橙色",
  yellow: "黄色",
  cyan: "青色",
  purple: "紫色",
  magenta: "洋红"
}

/**
 * 生成色板
 */
const getPalette = (arr) => {
  return arr.map((x, i) => {
    const nameEn =
      typeof x === "string"
        ? x.match(/[a-zA-Z]+/)[0]
        : x.nameEn.match(/[a-zA-Z]+/)[0]
    const nameCn = typeof x === "string" ? COLOR_NAME[nameEn] : x.nameCn
    const index =
      typeof x === "string" ? x.match(/\d+/)[0] : x.nameEn.match(/\d+/)[0]
    const hex = typeof x === "string" ? cubeColors[x] : x.hex
    const no = i + 1 < 10 ? `0${i + 1}` : i + 1
    // console.log("colorName", nameEn, nameCn, index)
    return {
      nameEn,
      nameCn,
      index,
      no,
      hex
    }
  })
}

const bluePaletteArr = ["blue6", "blue5", "blue4", "blue3", "blue2", "blue1"]

const colorPaletteArr1 = [
  "blue5",
  { nameEn: "cyan5", nameCn: "青色", hex: "#15D6D6" },
  { nameEn: "yellow5", nameCn: "黄色", hex: "#FFD11D" },
  "orange4",
  "red5",
  "magenta5",
  "purple4",
  "magenta2",
  { nameEn: "cyan2", nameCn: "青色", hex: "#92FCF2" },
  "blue2",
  "purple1",
  "orange2",
  "red2",
  "yellow2",
  { nameEn: "cyan1", nameCn: "青色", hex: "#BDFFF6" },
  "blue1",
  "magenta1",
  "orange1",
  "gray2",
  "gray3"
]

const colorPaletteArr2 = [
  "blue5",
  { nameEn: "cyan4", nameCn: "青色", hex: "#3BE3DD" },
  "yellow4",
  "orange4",
  "red4",
  "magenta4",
  "purple3",
  "magenta1",
  { nameEn: "cyan2", nameCn: "青色", hex: "#92FCF2" },
  "blue2",
  "purple1",
  "orange1",
  "red1",
  "yellow2",
  "green1",
  "blue1",
  "gray2",
  "gray3"
]

const bluePalette = getPalette(bluePaletteArr)
const colorPalette1 = getPalette(colorPaletteArr1)
const colorPalette2 = getPalette(colorPaletteArr2)
const blueColors = bluePalette.map((x) => x.hex)
const colors1 = colorPalette1.map((x) => x.hex)
const colors2 = colorPalette2.map((x) => x.hex)
// console.log("bluePalette", bluePalette)
// console.log("colorPalette1", colorPalette1)
// console.log("colorPalette2", colorPalette2)

export default {
  data() {
    return {
      bluePaletteArr,
      bluePalette,
      colorPalette1,
      colorPalette2,
      blueColors,
      colors1,
      colors2
    }
  },
  methods: {}
}
