import _ from "lodash"
import chroma from "chroma-js"
import * as cubeColors from "@cndinfo/cube-design-styles/colors/variables"
import { hexContrast } from "./utils"

const COLOR_NAME = {
  black: "",
  white: "",
  gray: "中性色",
  blue: "主题色",
  green: "成功色",
  red: "危险色",
  orange: "警告色",
  yellow: "黄色",
  cyan: "青色",
  purple: "紫色",
  magenta: "洋红色"
}

/**
 * 首字母大写
 */
const upperCaseFirst = (s) => s.replace(/^./, (m) => m.toUpperCase())

export const cubeColorList = [
  "blue",
  "green",
  "red",
  "orange",
  "yellow",
  "cyan",
  "purple",
  "magenta",
  "gray"
].reduce((p, c) => {
  const vs = Array.from(Array(10).keys()).map((x, i) => ({
    colorName: `${c}${x}`,
    hex: cubeColors[`${c}${x}`]
  }))
  vs.unshift({
    topTitle:
      `对比度 ` + hexContrast(cubeColors[`${c}5`], "#ffffff"),
    colorName: upperCaseFirst(`${c}5 ${COLOR_NAME[c]}`),
    hex: cubeColors[`${c}5`]
  })

  return Object.assign(p, {
    [c]: vs
  })
}, {})
// console.log("cubeColorList", cubeColorList)

const cubeColorBlackWhite = ["white", "black"].reduce((p, c) => {
  const vs = Array.from(Array(10).keys()).map((x, i) => ({
    colorName: `${c}${x}`,
    hex: cubeColors[`${c}${x}`],
    aliasHex:
      chroma(cubeColors[`${c}${x}`]).hex("rgb").toUpperCase() +
      " " +
      `${(chroma(cubeColors[`${c}${x}`]).alpha() * 100).toFixed(0)}%`
  }))

  return Object.assign(p, {
    [c]: vs
  })
}, {})
// console.log("cubeColorBlackWhite", cubeColorBlackWhite)

export const cubeColorStates = ["blue", "green", "red", "orange"].reduce(
  (p, c) => {
    const vs = [
      {
        state: "点击/激活 Active",
        colorName: upperCaseFirst(`${c}7`),
        hex: cubeColors[`${c}7`]
      },
      {
        state: "默认（主色）",
        colorName: upperCaseFirst(`${c}5`),
        hex: cubeColors[`${c}5`]
      },
      {
        state: "悬停 Hover",
        colorName: upperCaseFirst(`${c}4`),
        hex: cubeColors[`${c}4`]
      },
      {
        state: "禁用 Disabled",
        colorName: upperCaseFirst(`${c}1`),
        hex: cubeColors[`${c}1`]
      }
      // {
      //   state: "背景 Background",
      //   colorName: upperCaseFirst(`${c}0`),
      //   hex: cubeColors[`${c}0`]
      // }
    ]

    return Object.assign(p, {
      [c]: vs
    })
  },
  {}
)
// console.log("cubeColorStates", cubeColorStates)

export default {
  data() {
    return {
      listFeatures: _.pick(cubeColorList, ["blue", "green", "red", "orange"]),
      listExpand: _.pick(cubeColorList, [
        "yellow",
        "cyan",
        "purple",
        "magenta"
      ]),
      listNeutralAll: Object.assign(
        { gray: _.pick(cubeColorList, ["gray"]).gray.slice(1) },
        cubeColorBlackWhite
      ),
      cubeColorStates
    }
  },
  methods: {
    getColorStateName(color) {
      return {
        blue: "信息",
        green: "成功",
        red: "错误",
        orange: "警告"
      }[color]
    },
    copyColor(color) {
      if ("clipboard" in navigator) {
        navigator.clipboard.writeText(color)
        this.$message.success("复制成功")
        return
      }

      const textarea = document.createElement("textarea")
      textarea.textContent = color
      textarea.style.width = 0
      textarea.style.height = 0
      document.body.appendChild(textarea)

      const selection = document.getSelection()
      const range = document.createRange()
      range.selectNode(textarea)
      selection.removeAllRanges()
      selection.addRange(range)

      document.execCommand("copy")
      selection.removeAllRanges()
      document.body.removeChild(textarea)

      this.$message.success("复制成功")
    }
  }
}
