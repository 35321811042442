<template>
  <div class="cdm-docs-color-palettes chromatic">
    <div
      v-for="(item, index) in listExpand"
      :key="index"
      class="cdm-docs-color-palettes-list"
    >
      <div
        v-for="(listLi, itemIndex) in item"
        :key="itemIndex"
        class="cdm-docs-color-palettes-list-item"
        :style="{ background: listLi.hex }"
        @click="copyColor(listLi.hex)"
      >
        <p v-if="listLi.topTitle" class="cdm-docs-color-palettes-list-item-top">
          {{ listLi.topTitle }}
        </p>
        <p class="cdm-docs-color-palettes-list-item-bottom">
          <span>{{ listLi.colorName }}</span>
          <span>{{ listLi.hex }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ColorMixin from "./mixin"

export default {
  mixins: [ColorMixin]
}
</script>

<style lang="scss" scoped>
@import "./color.scss";
</style>
