export default {
  heroImage: "/images/hero.png",
  heroText: "Cube Docs Template",
  tagline: "静态文档脚手架",
  actions: [
    {
      text: "开发指南",
      link: "/docs/tutorial",
      type: "primary"
    },
    {
      text: "AMS",
      link: "https://ams.cube.cndinfo.com",
      type: "secondary"
    }
  ],
  features: [
    {
      title: "简洁至上",
      details:
        "以 Markdown、Vue 为中心的项目结构，以最少的配置帮助你专注于写作。"
    },
    {
      title: "灵活自由",
      details: "可自由定制任意功能，没有任何限制。"
    },
    {
      title: "主题",
      details: "所有样式均已变量化，你可以快速地创建一个你自己的主题。"
    }
  ]
}
