export const sidebarConfig = {
  "/docs/developing/": [
    {
      text: "快速上手",
      link: "/docs/developing/quick-start",
      collapsed: false,
      depth: 1
    },
    {
      text: "CSS 变量",
      link: "/docs/developing/css-variables",
      collapsed: false,
      depth: 1
    },
    {
      text: "图表开发",
      link: "/docs/developing/charts",
      collapsed: false,
      depth: 1
    },
    {
      text: "行为分析",
      link: "/docs/developing/analytics",
      collapsed: false,
      depth: 1
    },
    {
      text: "更新日志",
      link: "/docs/developing/changelog",
      collapsed: false,
      depth: 1
    },
    {
      text: "常见问题",
      link: "/docs/developing/faq",
      collapsed: false,
      depth: 1
    }
  ],
  "/docs/design/": [
    {
      text: "设计规范",
      collapsed: false,
      depth: 1,
      children: [
        {
          text: "色彩 Color",
          link: "/docs/design/guidelines/color"
        },
        {
          text: "字体 Typography",
          link: "/docs/design/guidelines/typography"
        },
        {
          text: "图表 Chart",
          link: "/docs/design/guidelines/chart"
        }
      ]
    }
    // {
    //   text: "设计模式",
    //   collapsed: false,
    //   depth: 1,
    //   children: [
    //     {
    //       text: "Card 卡片组件",
    //       link: "/docs/design/components/card"
    //     }
    //   ]
    // }
  ],
  "/docs/components/": [
    {
      text: "基础组件",
      collapsed: false,
      depth: 1,
      children: [
        {
          text: "Avatar 头像",
          link: "/docs/components/avatar"
        },
        {
          text: "BackTop 返回顶部",
          link: "/docs/components/back-top"
        },
        {
          text: "Badge 徽标",
          link: "/docs/components/badge"
        },
        {
          text: "Button 按钮",
          link: "/docs/components/button"
        },
        {
          text: "Checkbox 复选框",
          link: "/docs/components/checkbox"
        },
        {
          text: "CountDown 倒计时",
          link: "/docs/components/count-down"
        },
        {
          text: "Divider 分割线",
          link: "/docs/components/divider"
        },

        {
          text: "Expansion 折叠展开",
          link: "/docs/components/expansion"
        },
        {
          text: "Icon 图标",
          link: "/docs/components/icon"
        },
        {
          text: "Image 图片",
          link: "/docs/components/image"
        },
        {
          text: "Input 输入框",
          link: "/docs/components/input"
        },
        {
          text: "Layout 布局",
          link: "/docs/components/layout"
        },
        {
          text: "Loading 加载",
          link: "/docs/components/loading"
        },
        {
          text: "NoticeBar 通告栏",
          link: "/docs/components/notice-bar"
        },
        {
          text: "Notify 通知",
          link: "/docs/components/notify"
        },
        {
          text: "Overlay 遮罩层",
          link: "/docs/components/overlay"
        },
        {
          text: "ProgressBar 进度条",
          link: "/docs/components/progress-bar"
        },
        {
          text: "ProgressRing 环形进度条",
          link: "/docs/components/progress-ring"
        },
        {
          text: "PullRefresh 下拉刷新",
          link: "/docs/components/pull-refresh"
        },
        {
          text: "Price 价格",
          link: "/docs/components/price"
        },
        {
          text: "Radio 单选框",
          link: "/docs/components/radio"
        },
        {
          text: "Rate 评分",
          link: "/docs/components/rate"
        },
        {
          text: "Result 结果",
          link: "/docs/components/result"
        },
        {
          text: "SafeArea 安全区",
          link: "/docs/components/safe-area"
        },
        {
          text: "Selector 选择组",
          link: "/docs/components/selector"
        },
        {
          text: "Skeleton 骨架屏",
          link: "/docs/components/skeleton"
        },
        {
          text: "Slider 滑块",
          link: "/docs/components/slider"
        },
        {
          text: "Stepper 步进器",
          link: "/docs/components/stepper"
        },
        {
          text: "Sticky 吸顶",
          link: "/docs/components/sticky"
        },
        {
          text: "Switch 开关",
          link: "/docs/components/switch"
        },
        {
          text: "Tag 标签",
          link: "/docs/components/tag"
        },
        {
          text: "Text 文本",
          link: "/docs/components/text"
        },
        {
          text: "Textarea 文本域",
          link: "/docs/components/textarea"
        },
        {
          text: "Toast 轻提示",
          link: "/docs/components/toast"
        },
        {
          text: "Transition 过渡动画",
          link: "/docs/components/transition"
        },
        {
          text: "Watermark 水印",
          link: "/docs/components/watermark"
        }
      ]
    },
    {
      text: "复合组件",
      collapsed: false,
      depth: 1,
      children: [
        {
          text: "ActionBar 按钮操作栏",
          link: "/docs/components/action-bar"
        },
        {
          text: "ActionSheet 动作面板",
          link: "/docs/components/action-sheet"
        },
        {
          text: "Anchor 锚点",
          link: "/docs/components/anchor"
        },
        {
          text: "Calendar 日历",
          link: "/docs/components/calendar"
        },
        {
          text: "Card 卡片",
          link: "/docs/components/card"
        },
        {
          text: "Cascader 级联选择",
          link: "/docs/components/cascader"
        },
        {
          text: "Cell 单元格",
          link: "/docs/components/cell"
        },
        {
          text: "Collapse 折叠面板",
          link: "/docs/components/collapse"
        },
        {
          text: "DatePicker 时间选择器",
          link: "/docs/components/date-picker"
        },
        {
          text: "Dialog 对话框",
          link: "/docs/components/dialog"
        },
        {
          text: "DropMenu 下拉菜单",
          link: "/docs/components/drop-menu"
        },
        {
          text: "FloatingButton 悬浮按钮",
          link: "/docs/components/floating-button"
        },
        {
          text: "Footer 页脚",
          link: "/docs/components/footer"
        },
        {
          text: "Form 表单",
          link: "/docs/components/form"
        },
        {
          text: "Keyboard 键盘",
          link: "/docs/components/keyboard"
        },
        {
          text: "ImageViewer 图片查看器",
          link: "/docs/components/image-viewer"
        },

        {
          text: "IndexList 索引列表",
          link: "/docs/components/index-list"
        },
        {
          text: "NavBar 顶部导航",
          link: "/docs/components/nav-bar"
        },
        {
          text: "Pagination 分页",
          link: "/docs/components/pagination"
        },
        {
          text: "Picker 选择器",
          link: "/docs/components/picker"
        },
        {
          text: "Popover 气泡弹出框",
          link: "/docs/components/popover"
        },
        {
          text: "Popup 弹出层",
          link: "/docs/components/popup"
        },
        {
          text: "ScrollList 滚动列表",
          link: "/docs/components/scroll-list"
        },
        {
          text: "ScrollingScreenshot 长截图",
          link: "/docs/components/scrolling-screenshot"
        },
        {
          text: "SearchBar 搜索栏",
          link: "/docs/components/search-bar"
        },
        {
          text: "SelectPicker 单复选选择器",
          link: "/docs/components/select-picker"
        },
        {
          text: "SideBar 侧边导航",
          link: "/docs/components/side-bar"
        },
        {
          text: "Signature 签名",
          link: "/docs/components/signature"
        },
        {
          text: "Steps 步骤条",
          link: "/docs/components/steps"
        },
        {
          text: "Soduku 宫格",
          link: "/docs/components/soduku"
        },
        {
          text: "SubmitBar 提交表单栏",
          link: "/docs/components/submit-bar"
        },
        {
          text: "SwipeAction 滑动操作",
          link: "/docs/components/swipe-action"
        },
        {
          text: "Swiper 轮播图",
          link: "/docs/components/swiper"
        },
        {
          text: "Tabbar 标签栏",
          link: "/docs/components/tabbar"
        },
        {
          text: "Tabs 标签页",
          link: "/docs/components/tabs"
        },
        {
          text: "Table 表格",
          link: "/docs/components/table"
        },
        {
          text: "Tree 树形控件",
          link: "/docs/components/tree"
        },
        {
          text: "TreeSelect 分类选择",
          link: "/docs/components/tree-select"
        },
        {
          text: "Upload 文件上传",
          link: "/docs/components/upload"
        }
      ]
    },
    {
      text: "业务组件",
      collapsed: false,
      depth: 1,
      children: [
        {
          text: "AddressCard 收货地址卡片",
          link: "/docs/components/address-card"
        },
        {
          text: "AdvancedSearch 高级搜索",
          link: "/docs/components/advanced-search"
        },
        {
          text: "ECharts 图表",
          link: "/docs/components/echarts"
        },
        {
          text: "InputValidator 输入验证器",
          link: "/docs/components/input-validator"
        },
        {
          text: "ProductCard 商品卡片",
          link: "/docs/components/product-card"
        },
        {
          text: "ProductCategory 商品分类",
          link: "/docs/components/product-category"
        },
        {
          text: "ProductDetailAction 商品详情导航",
          link: "/docs/components/product-detail-action"
        },
        {
          text: "SearchList 列表搜索",
          link: "/docs/components/search-list"
        },
        {
          text: "ShoppingCart 购物车",
          link: "/docs/components/shopping-cart"
        },
        {
          text: "Sku 商品规格",
          link: "/docs/components/sku"
        },
        {
          text: "SkuCard 商品规格卡片",
          link: "/docs/components/sku-card"
        },
        {
          text: "Terms 协议",
          link: "/docs/components/terms"
        }
      ]
    }
  ]
}
