<template>
  <div :class="pageClasses">
    <Navbar
      :reverse="true"
      :hide-search="true"
      :nav-menus="navMenus"
      @toggle-sidebar="toggleSidebar"
    />
    <div class="sidebar-mask" @click="toggleSidebar(false)" />
    <Sidebar
      :items="sideMenus"
      :sidebar-depth="sidebarDepth"
      :nav-menus="navMenus"
    />
    <div class="circle-image">
      <img
        src="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-circle-2.svg"
      >
    </div>
    <div class="section">
      <div class="main-section">
        <div class="main-section-container">
          <div class="main-section-introduce">
            <div class="main-section-title">Cube Design Mobile</div>
            <div class="main-section-description">
              建发信息企业级移动端设计系统
            </div>
            <div class="main-section-button-action">
              <cube-button
                type="primary"
                size="large"
                @click="$router.push({ path: '/docs/developing/quick-start' })"
              >开始使用</cube-button>
              <!-- <cube-button
                type="primary"
                size="large"
                style="background-color: #fff; color: #1259e5"
                @click="$router.push({ path: '/docs/design/guidelines/colors' })"
              >设计规范</cube-button> -->
            </div>
          </div>
          <div class="doc-image-list">
            <div class="doc-image-item first">
              <img
                class="doc-image"
                src="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-square-3.png"
              >
            </div>
            <div class="doc-image-item second">
              <img
                class="doc-image"
                src="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-square-2.png"
              >
            </div>
            <div class="doc-image-item last">
              <img
                class="doc-image"
                src="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-square-1.png"
              >
            </div>
          </div>
          <div class="image-prototype-container">
            <img
              class="static-image pc"
              src="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-mobile-prototype.png"
            >
            <img
              class="static-image mobile"
              src="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-mobile-prototype-small.png"
            >
          </div>
        </div>
      </div>
      <div class="content-section">
        <div class="product-introduce">
          <div class="product-item">
            <img
              height="32"
              class="product-item-image"
              src="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-icon-1.svg"
            >
            <div class="product-item-title">兼容性</div>
            <div class="product-item-desc">
              兼容 App、H5 和小程序，一次开发，多端运行
            </div>
          </div>
          <div class="product-item">
            <img
              height="32"
              class="product-item-image"
              src="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-icon-2.svg"
            >
            <div class="product-item-title">主题化</div>
            <div class="product-item-desc">
              所有样式已变量化，可以方便调整或创造自己的主题
            </div>
          </div>
          <div class="product-item">
            <img
              height="32"
              class="product-item-image"
              src="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-icon-3.svg"
            >
            <div class="product-item-title">组件丰富</div>
            <div class="product-item-desc">
              合计 73 个组件，覆盖基础组件到业务组件
            </div>
          </div>
          <div class="product-item">
            <img
              height="32"
              class="product-item-image"
              src="https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-icon-4.svg"
            >
            <div class="product-item-title">案例</div>
            <div class="product-item-desc">
              拥有大量的页面案例，复制即用，减少开发者的重复工作
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/index.js"
import { sidebarConfig } from "config/sidebar"
import { matchSideBar } from "@/utils"
import { navbarConfig } from "config/navbar"
import { resolveNavLinks } from "../src/utils"

export default {
  name: "Home",
  components: {
    Navbar
  },
  data() {
    return {
      sideMenus: [],
      isSidebarOpen: false,
      sidebarDepth: 4,
      navMenus: [],
      isDragStart: false,
      pointerRelativePosition: {
        x: 0,
        y: 0
      },
      clientY: 0,
      sectionScrollHeight: 0
    }
  },
  computed: {
    pageClasses() {
      return [
        {
          "sidebar-open": this.isSidebarOpen,
          "no-sidebar": !this.hasSideBar,
          container: true
        }
      ]
    },
    hasSideBar() {
      return this.sideMenus && this.sideMenus.length
    }
  },
  watch: {
    $route(to, from) {
      // 建立一个宏任务 进入事件执行的最后阶段，方便获取最新的dom对象
      setTimeout(() => {
        this.sideMenus = matchSideBar(this.$route.path, sidebarConfig)
      })
    }
  },
  created() {
    this.sideMenus = matchSideBar(this.$route.path, sidebarConfig)
  },
  mounted() {
    this.$router.afterEach(() => {
      this.isSidebarOpen = false
    })
    this.navMenus = resolveNavLinks(navbarConfig)
  },
  methods: {
    toggleSidebar(to) {
      this.isSidebarOpen = typeof to === "boolean" ? to : !this.isSidebarOpen
      this.$emit("toggle-sidebar", this.isSidebarOpen)
    },
    onDragstart(event) {
      this.isDragStart = true
      let touch
      if (event.touches) {
        touch = event.touches[0]
      } else {
        touch = event
      }
      this.clientY = event.target.offsetTop
      console.log(this.clientY)
      const mainSectionDom = document.querySelector(".main-section")
      this.sectionScrollHeight = mainSectionDom.scrollHeight
      this.pointerRelativePosition.x = touch.clientX - event.target.offsetLeft
      this.pointerRelativePosition.y = touch.clientY - event.target.offsetTop
    },
    onDrag(event) {
      if (this.isDragStart) {
        let touch
        if (event.touches) {
          touch = event.touches[0]
        } else {
          touch = event
        }
        const mainSectionDom = document.querySelector(".main-section")
        const position = touch.clientY - this.pointerRelativePosition.y
        console.log(position, "position", this.clientY)
        // 设置拖拽的边界
        if (position <= 372 && position >= 318) {
          let sectionScrollHeight
          const imageDom = document.querySelector(".image-prototype-container")
          imageDom.setAttribute("style", `top:${position}px`)
          if (position < this.clientY) {
            sectionScrollHeight =
              this.sectionScrollHeight + Math.abs(position - this.clientY)
          } else {
            sectionScrollHeight =
              this.sectionScrollHeight - Math.abs(position - this.clientY)
          }
          mainSectionDom.setAttribute(
            "style",
            `height:${sectionScrollHeight + 30}px`
          )
        }
        document.addEventListener(
          "touchmove",
          function() {
            event.preventDefault()
          },
          false
        )
      }
    },
    onDragend() {
      this.isDragStart = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/_variables";
.container {
  box-sizing: border-box;
  background: #fff;
  height: 100%;
  padding-top: 76px;
  &.sidebar-open {
    .sidebar-mask {
      display: block;
    }
  }
  &.no-sidebar {
    .sidebar {
      display: none;
      @media (max-width: $MQMobile) {
        display: block;
      }
    }
  }
  .sidebar-mask {
    position: fixed;
    z-index: 8;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
  }

  ::v-deep .navbar {
    box-sizing: border-box;
    background-color: transparent;
    border-width: 0;
    .links {
      background-color: transparent;
    }
  }
  .circle-image {
    position: fixed;
    top: -25vw;
    left: -25vw;
    width: 50vw;
    height: 50vw;
    img {
      width: 50vw;
      height: 50vw;
      object-fit: contain;
    }
  }
  .section {
    background: #fff;
  }
  .main-section {
    height: 694px;
    background: url(https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-home-bg.png)
      no-repeat;
    background-size: cover;
    margin-top: -76px;
    padding-top: 76px;
    &-container {
      height: calc(100% - 76px);
      max-width: 1484px;
      margin: 0 auto;
      padding: 0 30px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .main-section-introduce {
        z-index: 9999;
      }
      .main-section-title {
        font-size: 74px;
        font-weight: bold;
        color: #fff;
        line-height: 74px;
        margin-top: 204px;
      }
      .main-section-description {
        font-size: 30px;
        color: #fff;
        line-height: 28px;
        margin-top: 34px;
      }
      .main-section-button-action {
        margin-top: 48px;
      }
      .image-prototype-container {
        position: relative;
        margin-top: 79px;
        .static-image {
          height: 300px;
          margin: 24px;
          object-fit: contain;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          pointer-events: none;
          &.pc {
            display: inline;
          }
          &.mobile {
            display: none;
          }
        }
      }
      .doc-image-list {
        position: absolute;
        .doc-image-item {
          position: relative;
          width: 200px;
          height: 200px;
          &.first {
            bottom: -27px;
            right: 111px;
            .doc-image {
              transform: rotate(24deg);
              width: 70px;
              height: 70px;
            }
          }
          &.second {
            bottom: 69px;
            right: 1px;
            .doc-image {
              transform: rotate(135deg);
              width: 100px;
              height: 100px;
            }
          }
          &.last {
            bottom: 127px;
            right: -70px;
          }
          .doc-image {
            width: 200px;
            height: 200px;
            object-fit: contain;
            display: block;
          }
        }
      }
    }
  }
  .content-section {
    position: relative;
    top: -100px;
    max-width: 1084px;
    margin: 0 auto;
    padding: 0 56px;
    .product-introduce {
      box-sizing: border-box;
      min-height: 200px;
      border-radius: 12px;
      background-color: #fff;
      box-shadow: 0 12px 28px 0 rgba(113, 142, 204, 0.1);
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 0 32px 32px;
      .product-item {
        width: 210px;
        text-align: center;
        margin-top: 32px;

        &-image {
          vertical-align: middle;
          border-style: none;
        }
        &-title {
          margin-top: 24px;
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          color: #000;
        }
        &-desc {
          font-size: 14px;
          color: #697a8c;
          line-height: 24px;
          margin-top: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: $MQNarrow) {
  .container {
    .main-section {
      &-container {
        .doc-image-list {
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .container {
    .main-section {
      &-container {
        .image-prototype-container {
          margin-top: 0;
        }
      }
    }
  }
}
@media screen and (max-width: $MQMobile) {
  .container {
    background: url(https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-bg-mobile.png)
      no-repeat;
    background-size: cover;
    ::v-deep .sidebar {
      top: var(--docs-navbar-height);
      padding-top: 0;
      transform: translateX(-100%);
    }
    &.sidebar-open {
      .sidebar {
        transform: translateX(0);
      }
    }

    &.no-navbar {
      .sidebar {
        padding-top: 0;
      }
    }
    .circle-image {
      display: none;
    }
    .section {
      background: url(https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-bg-mobile.png)
        no-repeat;
      background-size: cover;
    }
    .main-section {
      background: none;
      padding-top: 12px;
      height: 512px;
      text-align: center;
      &-container {
        display: block;
        .main-section-introduce {
          .main-section-title {
            font-size: 38px;
            margin-top: 100px;
            text-align: left;
          }
          .main-section-description {
            margin-top: 15px;
            text-align: left;
            font-size: 20px;
          }
          .main-section-button-action {
            text-align: left;
          }
        }

        .doc-image-list {
          display: none;
        }
        .image-prototype-container {
          position: absolute;
          width: calc(100% - 60px);
          top: 320px;
          margin-top: 0;
          .static-image {
            width: 40vw;
            height: auto;
            object-fit: contain;
            &.mobile {
              display: inline;
            }
            &.pc {
              display: none;
            }
          }
        }
      }
    }
    .content-section {
      top: 24px;
    }
  }
}

@media screen and (max-width: $MQMobileNarrow) {
  .container {
    background: none;
    .section {
      background: url(https://front-end-huawei-cdn.devops.cndinfo.com/static/images/cdw-doc-bg-mobile.png)
        no-repeat;
      background-size: cover;
    }
    .main-section {
      &-container {
        .image-prototype-container {
          margin-top: 0;
          .static-image {
            width: 70vw;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
    .content-section {
      padding: 0 30px 30px;
    }
  }
}
</style>
