
    <template>
      <section class="markdown-container theme-default-content">
      <head class="markdown"></head><body class="markdown"><h1 class="markdown">卡片组件</h1>
<demo-block render-source="false" demo-height="800" custom-class="mobile" class="markdown">
        
        <template slot="iframe" class="markdown"><iframe ref="iframe1" frameborder="0" src="http://localhost:8080/pages/docs/card/basic" class="markdown"></iframe></template><template slot="source" class="markdown"><element-demo0 class="markdown"></element-demo0></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;div&gt;111&lt;/div&gt;
</code></pre></template></demo-block></body>
      </section>
    </template>
    <script>
    
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_vm._v("111")])])}]

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  