
    <template>
      <section class="markdown-container theme-default-content">
      <head class="markdown"></head><body class="markdown"><h1 class="markdown">图表 Chart</h1>
<h2 id="配色方案" class="markdown"><a class="header-anchor markdown" href="#配色方案">#</a> 配色方案</h2>
<demo-block hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo0 class="markdown"></element-demo0></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;template&gt;
  &lt;div class="cdm-docs-chart-palettes chromatic"&gt;
    &lt;Markdown&gt;
      &lt;h3&gt;单色方案&lt;/h3&gt;
      &lt;p&gt;
        当图表使用同一颜色的不同色值区分数据内容时，优先使用蓝色，推荐使用的蓝色色值区间范围为：蓝色（1～6）。
      &lt;/p&gt;
      &lt;p&gt;
        当图表仅出现单一色值时，为确保信息读取效率，推荐使用的蓝色色值区间范围为：蓝色（4～6）。
      &lt;/p&gt;
    &lt;/Markdown&gt;
    &lt;div class="cdm-docs-chart-palettes-list"&gt;
      &lt;div
        v-for="(item, index) in bluePalette"
        :key="index"
        class="cdm-docs-chart-palettes-list-item"
      &gt;
        &lt;p class="cdm-docs-chart-palettes-list-item-index"&gt;
          &lt;span&gt;{{ item.no }}.&lt;/span&gt;
        &lt;/p&gt;
        &lt;p
          class="cdm-docs-chart-palettes-list-item-rect"
          :style="{ backgroundColor: item.hex }"
        &gt;
          &lt;span style="display: none"&gt;{{ item.hex }}&lt;/span&gt;
        &lt;/p&gt;
        &lt;p class="cdm-docs-chart-palettes-list-item-bottom"&gt;
          &lt;span&gt;{{ item.nameCn }}{{ item.index }}&lt;/span&gt;
          &lt;span&gt;{{ item.hex }}&lt;/span&gt;
        &lt;/p&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;Markdown&gt;
      &lt;pre&gt;{{ JSON.stringify(blueColors) }}&lt;/pre&gt;
    &lt;/Markdown&gt;
    &lt;Markdown&gt;
      &lt;h3&gt;多色方案&lt;/h3&gt;
      &lt;p&gt;
        当图表需使用多种色彩时，建议参考下方所列举的配色。按照方案1:
        01~20、方案2: 01~18 的顺序依次运用到图表中。
      &lt;/p&gt;
    &lt;/Markdown&gt;
    &lt;Markdown&gt;
      &lt;h4&gt;配色方案1&lt;/h4&gt;
    &lt;/Markdown&gt;
    &lt;div class="cdm-docs-chart-palettes-list"&gt;
      &lt;div
        v-for="(item, index) in colorPalette1"
        :key="index"
        class="cdm-docs-chart-palettes-list-item"
      &gt;
        &lt;p class="cdm-docs-chart-palettes-list-item-index"&gt;
          &lt;span&gt;{{ item.no }}.&lt;/span&gt;
        &lt;/p&gt;
        &lt;p
          class="cdm-docs-chart-palettes-list-item-rect"
          :style="{ backgroundColor: item.hex }"
        &gt;
          &lt;span style="display: none"&gt;{{ item.hex }}&lt;/span&gt;
        &lt;/p&gt;
        &lt;p class="cdm-docs-chart-palettes-list-item-bottom"&gt;
          &lt;span&gt;{{ item.nameCn }}{{ item.index }}&lt;/span&gt;
          &lt;span&gt;{{ item.hex }}&lt;/span&gt;
        &lt;/p&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;Markdown&gt;
      &lt;pre&gt;{{ JSON.stringify(colors1) }}&lt;/pre&gt;
    &lt;/Markdown&gt;
    &lt;Markdown&gt;
      &lt;h4&gt;配色方案2（组件采用的默认方案）&lt;/h4&gt;
    &lt;/Markdown&gt;
    &lt;div class="cdm-docs-chart-palettes-list"&gt;
      &lt;div
        v-for="(item, index) in colorPalette2"
        :key="index"
        class="cdm-docs-chart-palettes-list-item"
      &gt;
        &lt;p class="cdm-docs-chart-palettes-list-item-index"&gt;
          &lt;span&gt;{{ item.no }}.&lt;/span&gt;
        &lt;/p&gt;
        &lt;p
          class="cdm-docs-chart-palettes-list-item-rect"
          :style="{ backgroundColor: item.hex }"
        &gt;
          &lt;span style="display: none"&gt;{{ item.hex }}&lt;/span&gt;
        &lt;/p&gt;
        &lt;p class="cdm-docs-chart-palettes-list-item-bottom"&gt;
          &lt;span&gt;{{ item.nameCn }}{{ item.index }}&lt;/span&gt;
          &lt;span&gt;{{ item.hex }}&lt;/span&gt;
        &lt;/p&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;Markdown&gt;
      &lt;pre&gt;{{ JSON.stringify(colors2) }}&lt;/pre&gt;
    &lt;/Markdown&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import ChartMixin from "./mixin"

export default {
  mixins: [ChartMixin]
}
&lt;/script&gt;

&lt;style lang="scss" scoped&gt;
@import "./chart.scss";
&lt;/style&gt;
</code></pre></template></demo-block><h2 id="配色范例" class="markdown"><a class="header-anchor markdown" href="#配色范例">#</a> 配色范例</h2>
<demo-block hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo1 class="markdown"></element-demo1></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;template&gt;
  &lt;!-- eslint-disable --&gt;
  &lt;div&gt;
    &lt;Markdown&gt;&lt;p&gt;单色&lt;/p&gt;&lt;/Markdown&gt;
    &lt;div ref="pie" style="width: 16.67%; height: 200px"&gt;&lt;/div&gt;
    &lt;Markdown&gt;&lt;p&gt;多色&lt;/p&gt;&lt;/Markdown&gt;
    &lt;div style="display: flex"&gt;
      &lt;div
        v-for="(item, index) in pieList.slice(0, 6)"
        :key="index"
        :id="item"
        style="width: 16.67%; height: 200px"
      &gt;&lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import ChartMixin from "./mixin"

export default {
  mixins: [ChartMixin],
  data() {
    return {
      pieList: ["pie1", "pie2", "pie3", "pie4", "pie5", "pie6"],
      pieData: [
        { value: 1048, name: "a" },
        { value: 735, name: "b" },
        { value: 580, name: "c" },
        { value: 484, name: "d" },
        { value: 300, name: "e" }
      ]
    }
  },
  mounted() {
    this.initChart()
    this.initPieList()
  },
  methods: {
    initChart() {
      const chartDom = this.$refs.pie
      // eslint-disable-next-line no-undef
      const pie = echarts.init(chartDom, "cube")
      const option = {
        series: [
          {
            color: this.blueColors,
            type: "pie",
            radius: ["40%", "70%"],
            left: "left",
            // center: ["20%", "50%"],
            label: {
              show: false
            },
            data: this.pieData
          }
        ]
      }

      pie.setOption(option)
    },
    initPieList() {
      this.pieList.forEach((x, i) =&gt; {
        console.log("x", x)
        // eslint-disable-next-line no-undef
        const pie = echarts.init(document.getElementById(x), "cube")
        const option = {
          series: [
            {
              type: "pie",
              radius: ["40%", "70%"],
              left: "left",
              // center: ["20%", "50%"],
              label: {
                show: false
              },
              data: this.pieData.slice(0, i + 2)
            }
          ]
        }

        pie.setOption(option)
      })
    }
  }
}
&lt;/script&gt;
</code></pre></template></demo-block><h2 id="图表示例" class="markdown"><a class="header-anchor markdown" href="#图表示例">#</a> 图表示例</h2>
<p class="markdown">以下示例均采用配色方案2。</p>
<demo-block hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo2 class="markdown"></element-demo2></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;template&gt;
  &lt;div&gt;
    &lt;div style="display: flex; flex-wrap: wrap; justify-content: space-between"&gt;
      &lt;div v-for="(item, index) in chartList" :key="index" style="width: 66%"&gt;
        &lt;!-- &lt;div v-for="(item, index) in chartList" :key="index "&gt; --&gt;
        &lt;!-- &lt;Markdown
          &gt;&lt;h4&gt;{{ item.name }}&lt;/h4&gt;&lt;/Markdown
        &gt; --&gt;
        &lt;p style="font-size: 16px; margin: 20px 0 12px"&gt;{{ item.name }}&lt;/p&gt;
        &lt;div :id="item.id" style="width: 100%; height: 340px"&gt;1&lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import chartList from "./chart-list"
console.log("chartList", chartList)

export default {
  data() {
    return {
      chartList
    }
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      chartList.forEach((item) =&gt; {
        echarts
          .init(document.getElementById(item.id), "cube")
          .setOption(item.option)
      })
    }
  }
}
&lt;/script&gt;
</code></pre></template></demo-block></body>
      </section>
    </template>
    <script>
    
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": ()=>import('./chart-palette.vue'),"element-demo1": ()=>import('./pie-example.vue'),"element-demo2": ()=>import('./chart-list.vue'),
        }
      }
    </script>
  