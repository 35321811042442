<template>
  <div class="cdm-docs-color-states">
    <div
      v-for="(item, index) in cubeColorStates"
      :key="index"
      class="cdm-docs-color-states-list"
    >
      <p class="cdm-docs-color-states-list-name">
        {{ getColorStateName(index) }}
      </p>
      <div class="cdm-docs-color-states-list-items">
        <div
          v-for="(listLi, itemIndex) in item"
          :key="itemIndex"
          class="cdm-docs-color-states-list-item"
          :style="{ background: listLi.hex }"
          @click="copyColor(listLi.hex)"
        >
          <p class="cdm-docs-color-states-list-item-state">
            {{ listLi.state }}
          </p>
          <p class="cdm-docs-color-states-list-item-bottom">
            <span>{{ listLi.colorName }}</span>
            <span>{{ listLi.hex }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorMixin from "./mixin"

export default {
  mixins: [ColorMixin]
}
</script>

<style lang="scss" scoped>
@import "./color.scss";
</style>
