<template>
  <div>
    <div style="display: flex; flex-wrap: wrap; justify-content: space-between">
      <div v-for="(item, index) in chartList" :key="index" style="width: 66%">
        <!-- <div v-for="(item, index) in chartList" :key="index "> -->
        <!-- <Markdown
          ><h4>{{ item.name }}</h4></Markdown
        > -->
        <p style="font-size: 16px; margin: 20px 0 12px">{{ item.name }}</p>
        <div :id="item.id" style="width: 100%; height: 340px">1</div>
      </div>
    </div>
  </div>
</template>

<script>
import chartList from "./chart-list"
console.log("chartList", chartList)

export default {
  data() {
    return {
      chartList
    }
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      chartList.forEach((item) => {
        echarts
          .init(document.getElementById(item.id), "cube")
          .setOption(item.option)
      })
    }
  }
}
</script>
