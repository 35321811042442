<template>
  <div class="cdm-docs-font-color">
    <p class="note">右上角为按 WCAG 规范计算的颜色对比度。</p>
    <div
      v-for="(item, index) in fontColorList"
      :key="index"
      :class="index"
      class="cdm-docs-font-color-list"
      :style="{ background: index === 'dark' ? 'black' : '' }"
    >
      <div
        v-for="(subItem, itemIndex) in item"
        :key="itemIndex"
        class="cdm-docs-font-color-list-item"
      >
        <div class="cdm-docs-font-color-list-item-top">
          <p
            class="cdm-docs-font-color-list-item-content"
            :style="{ color: subItem.hex }"
          >
            Aa
          </p>
          <span>{{
            subItem.contrast || hexContrast(subItem.hex, "#ffffff")
          }}</span>
        </div>
        <div
          class="cdm-docs-font-color-list-item-bar"
          :style="{ background: subItem.hex }"
          @click="copyColor(subItem.hex)"
        />
        <div class="cdm-docs-font-color-list-item-bottom">
          <span class="cdm-docs-font-color-list-item-name">
            {{ subItem.name }}
            {{ index !== "color" ? ` - ${subItem.color}` : "" }}
          </span>
          <span>{{ subItem.aliasHex || subItem.hex.toUpperCase() }}</span>
        </div>
        <p class="cdm-docs-font-color-list-item-desc">
          {{ subItem.desc }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "./mixin"

export default {
  mixins: [Mixin]
}
</script>

<style lang="scss" scoped>
@import "./typography.scss";
</style>
