<template>
  <div class="cdm-docs-chart-palettes chromatic">
    <Markdown>
      <h3>单色方案</h3>
      <p>
        当图表使用同一颜色的不同色值区分数据内容时，优先使用蓝色，推荐使用的蓝色色值区间范围为：蓝色（1～6）。
      </p>
      <p>
        当图表仅出现单一色值时，为确保信息读取效率，推荐使用的蓝色色值区间范围为：蓝色（4～6）。
      </p>
    </Markdown>
    <div class="cdm-docs-chart-palettes-list">
      <div
        v-for="(item, index) in bluePalette"
        :key="index"
        class="cdm-docs-chart-palettes-list-item"
      >
        <p class="cdm-docs-chart-palettes-list-item-index">
          <span>{{ item.no }}.</span>
        </p>
        <p
          class="cdm-docs-chart-palettes-list-item-rect"
          :style="{ backgroundColor: item.hex }"
        >
          <span style="display: none">{{ item.hex }}</span>
        </p>
        <p class="cdm-docs-chart-palettes-list-item-bottom">
          <span>{{ item.nameCn }}{{ item.index }}</span>
          <span>{{ item.hex }}</span>
        </p>
      </div>
    </div>
    <Markdown>
      <pre>{{ JSON.stringify(blueColors) }}</pre>
    </Markdown>
    <Markdown>
      <h3>多色方案</h3>
      <p>
        当图表需使用多种色彩时，建议参考下方所列举的配色。按照方案1:
        01~20、方案2: 01~18 的顺序依次运用到图表中。
      </p>
    </Markdown>
    <Markdown>
      <h4>配色方案1</h4>
    </Markdown>
    <div class="cdm-docs-chart-palettes-list">
      <div
        v-for="(item, index) in colorPalette1"
        :key="index"
        class="cdm-docs-chart-palettes-list-item"
      >
        <p class="cdm-docs-chart-palettes-list-item-index">
          <span>{{ item.no }}.</span>
        </p>
        <p
          class="cdm-docs-chart-palettes-list-item-rect"
          :style="{ backgroundColor: item.hex }"
        >
          <span style="display: none">{{ item.hex }}</span>
        </p>
        <p class="cdm-docs-chart-palettes-list-item-bottom">
          <span>{{ item.nameCn }}{{ item.index }}</span>
          <span>{{ item.hex }}</span>
        </p>
      </div>
    </div>
    <Markdown>
      <pre>{{ JSON.stringify(colors1) }}</pre>
    </Markdown>
    <Markdown>
      <h4>配色方案2（组件采用的默认方案）</h4>
    </Markdown>
    <div class="cdm-docs-chart-palettes-list">
      <div
        v-for="(item, index) in colorPalette2"
        :key="index"
        class="cdm-docs-chart-palettes-list-item"
      >
        <p class="cdm-docs-chart-palettes-list-item-index">
          <span>{{ item.no }}.</span>
        </p>
        <p
          class="cdm-docs-chart-palettes-list-item-rect"
          :style="{ backgroundColor: item.hex }"
        >
          <span style="display: none">{{ item.hex }}</span>
        </p>
        <p class="cdm-docs-chart-palettes-list-item-bottom">
          <span>{{ item.nameCn }}{{ item.index }}</span>
          <span>{{ item.hex }}</span>
        </p>
      </div>
    </div>
    <Markdown>
      <pre>{{ JSON.stringify(colors2) }}</pre>
    </Markdown>
  </div>
</template>

<script>
import ChartMixin from "./mixin"

export default {
  mixins: [ChartMixin]
}
</script>

<style lang="scss" scoped>
@import "./chart.scss";
</style>
