<template>
  <div class="changelog">
    <changelog />
  </div>
</template>

<script>
import Changelog from "./changelog.md"

export default {
  components: {
    Changelog
  }
}
</script>

<style lang="scss">
</style>
