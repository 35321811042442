export const navbarConfig = [
  {
    text: "设计",
    link: "/docs/design/guidelines/color"
  },
  {
    text: "开发",
    link: "/docs/developing/quick-start"
  },
  // {
  //   text: "开发",
  //   link: "/docs/developing/avatar",
  //   items: [
  //     {
  //       text: "工具样式类",
  //       link: "/docs/developing/classes"
  //     }
  //   ]
  // },
  {
    text: "组件",
    link: "/docs/components/avatar"
    // items: [
    //   {
    //     text: "通用组件",
    //     link: "/docs/components/action-bar"
    //   }
    // ]
  },
  {
    text: "资源",
    link: "/docs/resources/design"
  }
]
