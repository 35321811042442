<template>
  <div class="markdown-container"><slot /></div>
</template>

<script>
export default {
  name: "Markdown",
  data() {
    return {
      tagData: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "blockquote",
        "code",
        "image",
        "img",
        "a",
        "pre",
        "ul",
        "ol",
        "li",
        "strong"
      ], // 需要支持的标签
      hTag: ["h1", "h2", "h3", "h4", "h5", "h6"] // 需要给当前那些h标签添加锚点
    }
  },
  mounted() {
    this.VueToMd(this.$slots.default)
  },
  methods: {
    VueToMd(array, childrenName = "children") {
      if (!Array.isArray(array)) return
      const recursive = (array) => {
        array.forEach((dom) => {
          const tag = dom.tag
          if (tag === "pre") {
            dom.elm.classList.add("hljs")
          }
          if (this.tagData.includes(tag)) {
            dom.elm.classList.add("markdown")
          }
          if (this.hTag.includes(tag)) {
            const str = dom.elm.innerText
            dom.elm.innerHTML = `<a href="#${str}" class="header-anchor markdown">#</a> ${str}`
            dom.elm.id = str
          }
          const child = dom[childrenName]
          if (child && child.length) recursive(child)
        })
      }
      return recursive(array)
    }
  }
}
</script>

<style scoped></style>
