<template>
  <div :class="pageClasses">
    <Navbar
      :reverse="false"
      :hide-search="false"
      :nav-menus="navMenus"
      @toggle-sidebar="toggleSidebar"
    />
    <div class="sidebar-mask" @click="toggleSidebar(false)" />
    <Sidebar
      :items="sideMenus"
      :sidebar-depth="sidebarDepth"
      :nav-menus="navMenus"
    />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/index.js"
import { resolveNavLinks, matchSideBar } from "@/utils"
import { navbarConfig } from "config/navbar"
import { sidebarConfig } from "config/sidebar"
export default {
  components: {
    Navbar
  },
  data() {
    return {
      navMenus: [],
      isSidebarOpen: false,
      sideMenus: [],
      sidebarDepth: 4
    }
  },
  computed: {
    pageClasses() {
      return [
        {
          "sidebar-open": this.isSidebarOpen,
          "no-sidebar": !this.hasSideBar,
          demo: true
        }
      ]
    },
    hasSideBar() {
      return this.sideMenus && this.sideMenus.length
    }
  },
  mounted() {
    this.$router.afterEach(() => {
      this.isSidebarOpen = false
    })
    this.navMenus = resolveNavLinks(navbarConfig)
  },
  created() {
    this.sideMenus = matchSideBar(this.$route.path, sidebarConfig)
  },
  methods: {
    toggleSidebar(to) {
      this.isSidebarOpen = typeof to === "boolean" ? to : !this.isSidebarOpen
      this.$emit("toggle-sidebar", this.isSidebarOpen)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
