<template>
  <div class="cdm-docs-font-scale">
    <div class="cdm-docs-font-scale-title">
      <span class="name">别名</span>
      <span class="height">行高</span>
      <span class="step">字阶梯度</span>
      <span class="size">字号</span>
    </div>
    <template v-for="(item, i) in fontList">
      <div
        :key="i"
        :class="['font-scale-' + item.fontSize, i]"
        :style="{
          height: item.lineHeight + 'px'
        }"
      >
        <span class="name">{{ item.name }}</span>
        <span class="height">{{ item.lineHeight }}px</span>
        <span class="step">{{ item.step }}</span>
        <span
          class="size"
          :style="{
            fontSize: item.fontSize + 'px',
            lineHeight: item.lineHeight + 'px'
          }"
        >{{ item.size }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import Mixin from "./mixin"

export default {
  mixins: [Mixin]
}
</script>

<style lang="scss" scoped>
@import "./typography.scss";
</style>
