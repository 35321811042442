
    <template>
      <section class="markdown-container theme-default-content">
      <head class="markdown"></head><body class="markdown"><h1 class="markdown">设计资源</h1>
<p class="markdown">这里提供 Cube Design Mobile 相关的设计资源和设计工具的下载，更多设计资源正在整理和完善中。</p>
<h2 id="资源列表" class="markdown"><a class="header-anchor markdown" href="#资源列表">#</a> 资源列表</h2>
<demo-block hide-code="true" hide-border="true" class="markdown">
        
        <template slot="source" class="markdown"><element-demo0 class="markdown"></element-demo0></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;template&gt;
  &lt;div class="cube-source-content"&gt;
    &lt;ul class="cube-source-content__list"&gt;
      &lt;li
        v-for="(item, index) in cardList"
        :key="index"
        class="cube-source-content__list__item"
        @click="goPath(item.url)"
      &gt;
        &lt;div class="cube-source-content__list__item__inner"&gt;
          &lt;div
            v-if="item.tag"
            class="cube-source-content__list__item__inner-tag"
          &gt;
            {{ item.tag }}
          &lt;/div&gt;
          &lt;img
            :src="item.imageUrl"
            width="32"
            class="cube-source-content__list__item__inner-icon"
          &gt;
          &lt;h3 class="cube-source-content__list__item__inner-title"&gt;
            {{ item.name }}
          &lt;/h3&gt;
          &lt;div class="cube-source-content__list__item__inner-detail"&gt;
            {{ item.description }}
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/li&gt;
    &lt;/ul&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  name: "DesignList",
  data() {
    return {
      cardList: [
        {
          name: "设计规范标注",
          description: "色彩、字体、间距等设计规范",
          tag: "内网",
          url: "/docs/resources/measure#设计规范",
          imageUrl:
            "https://front-end-huawei-cdn.devops.cndinfo.com/static/images/measure.svg"
        },
        {
          name: "组件规范标注",
          description: "基础、复合、业务组件标注",
          tag: "内网",
          url: "/docs/resources/measure#组件规范",
          imageUrl:
            "https://front-end-huawei-cdn.devops.cndinfo.com/static/images/icon-component.svg"
        },
        {
          name: "页面布局模板",
          description: "列表、详情等常见页面布局",
          tag: "内网",
          url: "/docs/resources/measure#页面布局模板",
          imageUrl:
            "https://front-end-huawei-cdn.devops.cndinfo.com/static/images/icon-browser.svg"
        },
        {
          name: "摹客 RP 设计资源",
          description: "组件库 RP 文件",
          tag: "内网",
          url: "/docs/resources/mockplus",
          imageUrl:
            "https://front-end-huawei-cdn.devops.cndinfo.com/static/images/rp_logo.png"
        }
      ]
    }
  },
  methods: {
    goPath(url) {
      if (!url) return
      window.open(url)
    }
  }
}
&lt;/script&gt;

&lt;style lang="scss" scoped&gt;
.cube-source-content {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 100vw;
  padding: 0 24px;

  &amp;__list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    list-style: none;
    margin: 0;
    padding: 0;

    &amp;__item {
      border-radius: 10px;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
      padding: 1px;
      position: relative;
      width: calc(30% - 12px);

      &amp;__inner {
        border-radius: 8px;
        border: 1px transparent solid;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 128px;
        justify-content: flex-end;
        padding: 16px;
        background: #f9f9fb;

        &amp;-tag {
          border-bottom-right-radius: 9px;
          border-top-left-radius: 9px;
          left: 2px;
          padding: 6px 16px;
          position: absolute;
          top: 2px;
          background: #e4f0ec;
          color: #2ea26f;
        }

        &amp;-icon {
          position: absolute;
          right: 16px;
          top: 16px;
        }

        &amp;-title {
          display: block;
          font-size: 20px;
          margin-block-start: 16px;
          margin-block-end: 16px;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          // font-weight: bold;
        }

        &amp;-detail {
          color: #a4a4a6;
        }
      }

      &amp;__inner:hover {
        border: 1px #1f97a6 solid;
      }
    }
  }
}
@media (max-width: 1290px) {
  .cube-source-content__list__item {
    width: calc(50% - 12px);
  }
}
&lt;/style&gt;
</code></pre></template></demo-block></body>
      </section>
    </template>
    <script>
    
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": ()=>import('./design-list.vue'),
        }
      }
    </script>
  