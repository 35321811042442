import _ from "lodash"
import chroma from "chroma-js"
import * as cubeColors from "@cndinfo/cube-design-styles/colors/variables"
import { hexContrast } from "../color/utils"

function genFontSize(num) {
  const result = []
  for (let i = 10; i <= num; i += 2) {
    result.push({ label: `${i}px`, value: i })
  }
  return result
}

export default {
  data() {
    return {
      // prettier-ignore
      fontList: [
        { step: "-2", size: "10px 脚注", fontSize: 10, lineHeight: 14, name: "footnote" },
        { step: "-2", size: "12px 脚注", fontSize: 12, lineHeight: 16, name: "caption" },
        { step: "base", size: "14px 正文", fontSize: 14, lineHeight: 20, name: "body" },
        { step: "+2", size: "16px 正文", fontSize: 16, lineHeight: 22, name: "subhead" },
        { step: "+2", size: "18px 正文", fontSize: 18, lineHeight: 24, name: "headline" },
        { step: "+2", size: "20px 小标题", fontSize: 20, lineHeight: 28, name: "h6" },
        { step: "+4", size: "24px 小标题", fontSize: 24, lineHeight: 32, name: "h5" },
        { step: "+4", size: "28px 大标题", fontSize: 28, lineHeight: 36, name: "h4" },
        { step: "+4", size: "32px 大标题", fontSize: 32, lineHeight: 40, name: "h3" },
        { step: "+4", size: "36px 大标题", fontSize: 36, lineHeight: 44, name: "h2" },
        { step: "+4", size: "40px 大标题", fontSize: 40, lineHeight: 48, name: "h1" }
      ],
      fontSize: 48,
      fontSelectList: genFontSize(64),
      fontColorList: {
        // prettier-ignore
        light: [
          { name: "一级", color: "Gray9", hex: cubeColors.gray9, desc: "正文、标题、主要文字" },
          { name: "二级", color: "Gray6", hex: cubeColors.gray6, desc: "副标题、次要文字" },
          { name: "三级", color: "Gray4", hex: cubeColors.gray4, desc: "备注、占位符、注释性文字" },
          { name: "四级", color: "Gray3", hex: cubeColors.gray3, desc: "禁用状态文字" }
        ],
        // prettier-ignore
        dark: [
          { name: "一级", color: "White0", hex: cubeColors.white0, aliasHex: "#FFFFFF", contrast: "21" },
          { name: "二级", color: "White2", hex: cubeColors.white2, aliasHex: "#FFFFFF 75%", contrast: "11.42" },
          { name: "三级", color: "White4", hex: cubeColors.white4, aliasHex: "#FFFFFF 45%", contrast: "4.43" },
          { name: "四级", color: "White5", hex: cubeColors.white5, aliasHex: "#FFFFFF 30%", contrast: "2.48" }
        ],
        // prettier-ignore
        color: [
          { name: "品牌、强调、信息", color: "Blue5", hex: cubeColors.blue5 },
          { name: "成功、正向、积极", color: "Green5", hex: cubeColors.green5 },
          { name: "警告、提示", color: "Orange5", hex: cubeColors.orange5 },
          { name: "失败、错误、消息", color: "Red5", hex: cubeColors.red5 }
        ]
      },
      fontColorListLeft: [
        {
          background: "rgba(0, 0, 0, 0.9)",
          color: "#fff",
          text: "Font Gy1",
          style: "#000000 90%"
        },
        {
          background: "rgba(0, 0, 0, 0.6)",
          color: "#fff",
          text: "Font Gy2",
          style: "#000000 60%"
        },
        {
          background: "rgba(0, 0, 0, 0.4)",
          color: "#fff",
          text: "Font Gy3",
          style: "#000000 40%"
        },
        {
          background: "rgba(0, 0, 0, 0.26)",
          color: "#fff",
          text: "Font Gy4",
          style: "#000000 26%"
        }
      ],
      fontColorListRight: [
        {
          background: "rgba(255, 255, 255, 1)",
          color: "rgba(0,0,0,.9)",
          text: "Font Wh1",
          style: "#ffffff 100%"
        },
        {
          background: "rgba(255, 255, 255, 0.55)",
          color: "#fff",
          text: "Font Wh2",
          style: "#ffffff 55%"
        },
        {
          background: "rgba(255, 255, 255, 0.35)",
          color: "#fff",
          text: "Font Wh3",
          style: "#ffffff 35%"
        },
        {
          background: "rgba(255, 255, 255, 0.22)",
          color: "#fff",
          text: "Font Wh4",
          style: "#ffffff 22%"
        }
      ]
    }
  },

  methods: {
    hexContrast,
    copyColor(color) {
      if ("clipboard" in navigator) {
        navigator.clipboard.writeText(color)
        this.$message.success("复制成功")
        return
      }

      const textarea = document.createElement("textarea")
      textarea.textContent = color
      textarea.style.width = 0
      textarea.style.height = 0
      document.body.appendChild(textarea)

      const selection = document.getSelection()
      const range = document.createRange()
      range.selectNode(textarea)
      selection.removeAllRanges()
      selection.addRange(range)

      document.execCommand("copy")
      selection.removeAllRanges()
      document.body.removeChild(textarea)

      this.$message.success("复制成功")
    }
  }
}
