var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"demo-block",class:[
    _vm.blockClass,
    {
      hover: _vm.hideBorder === 'true' ? '' : _vm.hovering,
    },
    _vm.customClass
  ],on:{"mouseenter":function($event){_vm.hovering = true},"mouseleave":function($event){_vm.hovering = false}}},[(_vm.$slots.default)?_c('div',{staticClass:"demo-block-desc"},[_vm._t("default")],2):_vm._e(),(_vm.demoShow)?_c('div',{staticClass:"demo-block-demo",style:([_vm.demoStyle])},[(_vm.renderSource === 'true')?_vm._t("source"):_vm._e(),(_vm.renderIframe === 'true')?_vm._t("iframe"):_vm._e()],2):_vm._e(),(_vm.hideCode === 'false')?_c('div',{ref:"control",staticClass:"demo-block-footer",class:{ 'is-fixed': _vm.fixedControl }},[_c('transition',{attrs:{"name":"arrow-slide"}},[_c('code-copy',{attrs:{"code":_vm.code,"type":"icon"}})],1),_c('transition',{attrs:{"name":"text-slide"}},[_c('div',{staticClass:"icon-open",class:{ expanded: _vm.isExpanded },on:{"click":function($event){_vm.isExpanded = !_vm.isExpanded},"mouseleave":function($event){_vm.iconColor = _vm.isExpanded ? '#409eff':'#ccc'},"mouseenter":function($event){_vm.iconColor = '#409eff'}}},[_c('cube-tooltip',{attrs:{"content":_vm.isExpanded ? '隐藏代码' : '显示代码',"placement":"top"}},[_c('cube-icon',{attrs:{"name":"cube-expand-left-and-right-outline","color":_vm.iconColor,"width":"20px","height":"20px"}})],1)],1)])],1):_vm._e(),(_vm.hideCode === 'false')?_c('div',{ref:"meta",staticClass:"demo-block-source"},[_c('div',{staticClass:"highlight"},[_vm._t("highlight")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }