<template>
  <!-- eslint-disable -->
  <div>
    <Markdown><p>单色</p></Markdown>
    <div ref="pie" style="width: 16.67%; height: 200px"></div>
    <Markdown><p>多色</p></Markdown>
    <div style="display: flex">
      <div
        v-for="(item, index) in pieList.slice(0, 6)"
        :key="index"
        :id="item"
        style="width: 16.67%; height: 200px"
      ></div>
    </div>
  </div>
</template>

<script>
import ChartMixin from "./mixin"

export default {
  mixins: [ChartMixin],
  data() {
    return {
      pieList: ["pie1", "pie2", "pie3", "pie4", "pie5", "pie6"],
      pieData: [
        { value: 1048, name: "a" },
        { value: 735, name: "b" },
        { value: 580, name: "c" },
        { value: 484, name: "d" },
        { value: 300, name: "e" }
      ]
    }
  },
  mounted() {
    this.initChart()
    this.initPieList()
  },
  methods: {
    initChart() {
      const chartDom = this.$refs.pie
      // eslint-disable-next-line no-undef
      const pie = echarts.init(chartDom, "cube")
      const option = {
        series: [
          {
            color: this.blueColors,
            type: "pie",
            radius: ["40%", "70%"],
            left: "left",
            // center: ["20%", "50%"],
            label: {
              show: false
            },
            data: this.pieData
          }
        ]
      }

      pie.setOption(option)
    },
    initPieList() {
      this.pieList.forEach((x, i) => {
        console.log("x", x)
        // eslint-disable-next-line no-undef
        const pie = echarts.init(document.getElementById(x), "cube")
        const option = {
          series: [
            {
              type: "pie",
              radius: ["40%", "70%"],
              left: "left",
              // center: ["20%", "50%"],
              label: {
                show: false
              },
              data: this.pieData.slice(0, i + 2)
            }
          ]
        }

        pie.setOption(option)
      })
    }
  }
}
</script>
