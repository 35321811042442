import chroma from "chroma-js"

/**
 * 根据 WCAG 规范计算颜色对比度
 * @param {String} hex1 前景色
 * @param {String} hex2 背景色
 * @returns 对比度
 */
export const hexContrast = (hex1, hex2) => {
  const luminance = (r, g, b) => {
    const a = [r, g, b].map((v) => {
      v /= 255
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
    })
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
  }

  const rgb1 = chroma(hex1).rgb()
  const rgb2 = chroma(hex2).rgb()
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2])
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2])
  const brightest = Math.max(lum1, lum2)
  const darkest = Math.min(lum1, lum2)
  return ((brightest + 0.05) / (darkest + 0.05)).toFixed(2)
}
