var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cdm-docs-font-scale"},[_vm._m(0),_vm._l((_vm.fontList),function(item,i){return [_c('div',{key:i,class:['font-scale-' + item.fontSize, i],style:({
        height: item.lineHeight + 'px'
      })},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"height"},[_vm._v(_vm._s(item.lineHeight)+"px")]),_c('span',{staticClass:"step"},[_vm._v(_vm._s(item.step))]),_c('span',{staticClass:"size",style:({
          fontSize: item.fontSize + 'px',
          lineHeight: item.lineHeight + 'px'
        })},[_vm._v(_vm._s(item.size))])])]})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cdm-docs-font-scale-title"},[_c('span',{staticClass:"name"},[_vm._v("别名")]),_c('span',{staticClass:"height"},[_vm._v("行高")]),_c('span',{staticClass:"step"},[_vm._v("字阶梯度")]),_c('span',{staticClass:"size"},[_vm._v("字号")])])
}]

export { render, staticRenderFns }