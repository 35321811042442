import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../../docs/Home.vue"
import Layout from "../layouts/Layout"
import DefaultHome from "../views/Index"
import Demo from "../views/demo/index"
import docsRoutes from "./docs-route"
import { siteConfig } from "config/site"

const originalReplace = VueRouter.prototype.replace

// 防止跳转重复的路由，控制台提示报错信息
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const isCustom = siteConfig.customHome
const homeRoute = {
  path: "/",
  name: "Home",
  component: isCustom ? Home : Layout,
  children: isCustom
    ? []
    : [
      {
        path: "/",
        name: "DefaultHome",
        component: DefaultHome
      }
    ]
}

const routes = [
  homeRoute,
  {
    path: "/docs",
    name: "docs",
    component: Layout,
    children: docsRoutes
  },
  {
    path: "/demo",
    name: "demo",
    redirect: "/demo/list",
    component: Demo,
    children: [
      {
        path: "/demo/list",
        name: "demo-list",
        component: (resolve) => require(["@/views/demo/demo-list"], resolve)
      },
      {
        path: "/demo/detail",
        name: "demo-detail",
        component: (resolve) => require(["@/views/demo/demo-detail"], resolve)
      }
    ]
  }
]
const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_DOCS_VERSION,
  routes
})

export default router
